
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ApiHelper from "@/helpers/api-helper";
	import FeatureService from "@/services/FeatureService";

	@Component({})
	export default class FeatureSelect extends Vue {
		@Prop({ default: null, required: true }) private type;
		@Prop({ default: null, required: true }) private label;
		@Prop({ default: null, required: false }) private feature_ids;
		@Prop({ default: null, required: false }) private errors;

		@Prop({ default: "select", required: false }) private template;

		private features: any[] = [];
		private selected: any[] = [];

		mounted() {
			if (this.feature_ids) {
				this.selected = this.feature_ids;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			FeatureService.selectorList(this.type)
				.then((res) => {
					if (res.status === 200) {
						this.features = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
