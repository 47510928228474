import Store from '@/store/index'
import UserService from '@/session/services/UserService';

export const userResolver = (to, from, next) => {
	const userResolver = to.matched.some(record => record.meta.userResolver);
	if (userResolver) {
		const user = Store.getters.loggedUser;
		if (!user) {
			UserService.me()
				.then(res => {
					if (res.status === 200) {
						Store.commit('setUser', res.data);
						next();
					}
				})
				.catch(error => {
					if(error.response && error.response.status == 401 && error.response.data.error == 'token_expired'){
						next({ name: 'Login', params: {tokenExpired: true}});
					}
					return error;
				})
		} else {
			next();
		}
	} else {
		next();
	}
}
