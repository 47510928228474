
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import CityService from "@/services/CityService";
	import ApiHelper from "@/helpers/api-helper";
	import City from "@/models/city";

	@Component({})
	export default class CitySelect extends Vue {
		@Prop({ default: null, required: true }) private prov_id;
		@Prop({ default: null, required: false }) private id_city;
		@Prop({ default: null, required: false }) private errors;

		@Watch("prov_id")
		prov_id_change(value: string, oldValue: string) {
			this.id_prov = value;
			this.getSelectorList();
		}

		private items: any[] = [];
		private id_prov: any = null;
		private value = null;
		private search = null;
		private slug = "";
		private city: City = new City();

		mounted() {
			this.id_prov = this.prov_id;
			this.getSelectorList();
		}

		getSelectorList() {
			if (this.id_prov) {
				CityService.getSelectorList(this.id_prov)
					.then((res) => {
						if (res.status === 200) {
							this.items = res.data;
							this.value = this.id_city;
						}
					})
					.catch((error) => {
						ApiHelper.handleErrors(error, {}, this.$bus);
					});
			}
		}

		create(name) {
			this.string_to_slug(name);
			if (this.id_prov) {
				const component = this;
				this.city = {
					id: null,
					name: name,
					state_id: this.id_prov,
					slug: this.slug,
				};
				CityService.create(this.city)
					.then((res) => {
						if (res.status === 201) {
							this.getSelectorList();
						}
					})
					.catch((error) => {
						ApiHelper.handleErrors(error, component.$refs["obs"], this.$bus);
					});
			}
		}

		string_to_slug(str) {
			str = str.replace(/^\s+|\s+$/g, ""); // trim
			str = str.toLowerCase();

			// remove accents, swap ñ for n, etc
			const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			const to = "aaaaeeeeiiiioooouuuunc------";
			for (let i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
			}

			str = str
				.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
				.replace(/\s+/g, "-") // collapse whitespace and replace by -
				.replace(/-+/g, "-"); // collapse dashes

			this.slug = str;
		}
	}
