
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import TravelService from "@/services/TravelService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class TravelSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;
		@Prop({ default: null, required: false }) private valid;
		@Prop({ default: false, required: false }) private multiple;
		@Prop({ default: false, required: false }) private disabled;

		private items: any[] = [];
		private value = null;

		@Watch("model")
		model_change(value) {
			this.value = value;
		}

		mounted() {
			if (this.model) {
				this.value = this.model;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			TravelService.getSelectorList()
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
