
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ExtraServiceSelect from "@/components/inputs/ExtraServiceSelect.vue";
import Budget from '../../models/budget';

@Component({
	components: {
		ExtraServiceSelect,
	},
})
export default class TabBudgetExtraServices extends Vue {
	@Prop({ default: null, required: true }) private budget;
	@Prop({ default: null, required: false }) private errors;

	private extra_service_budget: any;

	constructor() {
		super();
		this.extra_service_budget = new Budget();
	}

	mounted() {
		if (this.budget) {
			this.extra_service_budget = Budget.copy(this.budget);
		}
	}

	$refs!: {
		extraServiceSelect: InstanceType<typeof ExtraServiceSelect>;
	};

	setPrice(value){
		this.$emit('setPrice', value);
		this.$emit("input", this.extra_service_budget.extra_service);
	}

	async validate() {
		this.$refs.extraServiceSelect.setPrice();
		this.$emit("input", this.extra_service_budget.extra_service);
		return true;
	}
}
