
	import {Component, Prop, Vue, Watch,} from "vue-property-decorator";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import AccommodationSelect from "@/components/inputs/AccommodationSelect.vue";
	import FeatureAccommodationSelect from "@/components/inputs/FeatureAccommodationSelect.vue";
	import AccommodationPriceSelect from "@/components/inputs/AccommodationPriceSelect.vue";

	@Component({
		components: {
			AccommodationSelect,
			FeatureAccommodationSelect,
			AccommodationPriceSelect,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TabAccommodationPackage extends Vue {
		@Prop({ default: null, required: true }) private packageAccommodation;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: true }) private people;
		@Prop({ default: null, required: false }) private errors;

		private packageAccommodationData: any;
		private travelId: any;
		private componentPeople: any;
		private increases = {};

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		@Watch("packageAccommodation")
		package_accommodation_change(value) {
			this.copyPackageAccommodation(value);
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.travelId = value;
		}

		@Watch("people")
		people_change(value) {
			this.componentPeople = value;
		}

		constructor() {
			super();
			this.packageAccommodationData = {
				id: null,
				feature_id: null,
				price_id: null,
				price: 0,
				prices: [],
				extras: [],
				extra_ids: [],
				increase_half_board: 0,
				increase_full_board: 0,
			}
			this.travelId = null;
		}

		mounted() {
			if (this.packageAccommodation) {
				this.copyPackageAccommodation(this.packageAccommodation);
			}
			if (this.travel_id) {
				this.travelId = this.travel_id;
			}
			if (this.people != null) {
				this.componentPeople = this.people;
			}
		}

		copyPackageAccommodation(data){
			this.packageAccommodationData.id = data.id;
			this.packageAccommodationData.feature_id = data.feature_id;
			this.packageAccommodationData.price_id = data.price_id;
			this.packageAccommodationData.prices = data.prices;
			this.packageAccommodationData.extras = data.extras;
			this.packageAccommodationData.extra_ids = data.extra_ids;
			this.packageAccommodationData.increase_half_board = data.increase_half_board;
			this.packageAccommodationData.increase_full_board = data.increase_full_board;
		}

		setAccommodationIdPricesAndExtras(data) {
			this.packageAccommodationData.id = data.id;
			this.packageAccommodationData.prices = data.prices;
			this.packageAccommodationData.extras = data.extras;
		}

		async validate() {
			this.$emit("input", this.packageAccommodationData);
			return await this.$refs.obs.validate();
		}

		setPrice(value){
			if (value) {
				this.packageAccommodationData.price = value.price
			}
			let total = 0;
			for (const price of this.packageAccommodationData.prices) {
				total += price.price;
			}
			for (const extra of this.packageAccommodationData.extras) {
				total += extra.price;
			}
			total += this.packageAccommodationData.price;
			this.$emit('setPrice', total);
		}
	}
