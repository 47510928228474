
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";

	@Component({})
	export default class DayPartSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;

		private selected: any = null;
		private values = [{'id' : 1, 'name' : 'Mañana/tarde'}, {'id' : 2, 'name' : 'Día completo'}]

		@Watch("model")
		model_change(value) {
			this.selected = value;
		}

		mounted() {
			this.selected = this.model;
		}
	}
