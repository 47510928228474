import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import BulkTransportTypes from "@/models/bulk_transport_types";

export default class TransportTypeService {
	private static fields = [
		'transport_types'
	]

	public static get(id) {
		return axios.get(`/transport_type/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/transport_type", { params: params });
	}

	public static getSelectorList() {
		return axios.get(`/transport_type/filter`);
	}

	public static bulkUpdate(transportType: BulkTransportTypes) {
		const formData = new FormData();
		formData.append('transport_types', JSON.stringify(transportType.transport_types));
		for (const index in transportType.transport_types) {
			if(transportType.transport_types[index].img_url && typeof transportType.transport_types[index].img_url.lastModified  === 'number')
				formData.append('img_url_' + index, transportType.transport_types[index].img_url);
		}
		return axios.post(`/transport_type/bulk/update`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static delete(id) {
		return axios.delete(`/transport_type/${id}`);
	}
}
