import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import ExtraService from "@/models/extra_service";

export default class ExtraServiceService {
	private static fields = [
		'id',
		'name',
		'description',
		'discount',
		'discount_amount',
		'travel_id',
		'status_id',
		'extra_services_prices',
		'provider_ids',
		'url'
	]

	public static get(id) {
		return axios.get(`/extra_service/${id}`)
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/extra_service", { params: params });
	}

	public static getSelectorList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/extra_service/filter`, { params: params });
	}

	public static create(extra_service: ExtraService) {
		const data = ApiHelper.buildParams(this.fields, extra_service);
		return axios.post('/extra_service', data);
	}

	public static update(extra_service: ExtraService) {
		const data = ApiHelper.buildParams(this.fields, extra_service);
		return axios.put(`/extra_service/${extra_service.id}`, data);
	}

	public static changeStatus(extra_service: ExtraService) {
		return axios.get(`/extra_service/changeStatus/${extra_service.id}/${extra_service.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/extra_service/${id}`);
	}
}
