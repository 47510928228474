<template>
	<PackagesTable/>
</template>

<script>
	import PackagesTable from "@/components/tables/PackagesTable";

	export default {
		name: 'Packages',
		components: {
			PackagesTable
		},
	}
</script>
