<template>
	<CustomerBudgetTable />
</template>

<script>
	import CustomerBudgetTable from "@/components/tables/CustomerBudgetTable.vue";

	export default {
		name: "CustomerBudgets",
		components: {
			CustomerBudgetTable
		}
	};
</script>
