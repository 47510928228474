import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Provider from "@/models/provider";

export default class ProviderService {
	private static fields = [
		'business_name',
		'cif',
		'addresss',
		'email',
		'phone',
		'contact_person',
		'contact_person_email',
		'contact_person_phone',
		'web',
		'note'
	];

	public static get(id) {
		return axios.get(`/provider/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/provider", { params: params });
	}

	public static getSelectorList() {
		return axios.get(`/provider/filter`);
	}

	public static create(provider: Provider) {
		const data = ApiHelper.buildParams(this.fields, provider);
		return axios.post('provider', data);
	}

	public static update(provider: Provider) {
		const data = ApiHelper.buildParams(this.fields, provider);
		return axios.put(`/provider/${provider.id}`, data);
	}

	public static delete(id) {
		return axios.delete(`/provider/${id}`);
	}
}
