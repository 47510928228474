import AccommodationPrice from '@/models/accommodation_price';
import AccommodationExtraPrice from '@/models/accommodation_extra_price';

export default class Accomodation {
	id: any;
	name: string;
	description: string;
	img_url: any;
	img_url_2: any;
	img_url_3: any;
	img_url_4: any;
	img_url_5: any;
	travel_id: any;
	travel_name: string;
	position: any;
	address: string;
	url: string;
	lat: any;
	lon: any;
	relevance: number;
	tripadvisor: string;
	status_id: any;
	feature_ids: any[];
	provider_ids: any[];
	features: any;
	increase_half_board_activated: any;
	increase_half_board: any;
	increase_full_board_activated: any;
	increase_full_board: any;
	price_ids: any[];
	prices: AccommodationPrice[];
	extras: AccommodationExtraPrice[];
	created_at: any;
	type_id: any;
	with_google_reviews: any;

	constructor() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.img_url = null;
		this.img_url_2 = null;
		this.img_url_3 = null;
		this.img_url_4 = null;
		this.img_url_5 = null;
		this.travel_id = null;
		this.travel_name = '';
		this.position = null;
		this.address = '';
		this.url = '';
		this.lat = null;
		this.lon = null;
		this.relevance = 1;
		this.tripadvisor = '';
		this.status_id = 0;
		this.feature_ids = [];
		this.features = { extras: [], center: null };
		this.price_ids = [];
		this.provider_ids = [];
		this.prices = [];
		this.extras = [];
		this.increase_half_board_activated = 0;
		this.increase_half_board = null;
		this.increase_full_board_activated = 0;
		this.increase_full_board = null;
		this.created_at = null;
		this.type_id = 0;
		this.with_google_reviews = 0;
	}

	public static copy(model: any) {
		const aux = new Accomodation();
		aux.id = model.id;
		aux.name = model.name;
		aux.description = model.description;
		if (model.img_url) {
			aux.img_url = model.img_url;
		} else {
			aux.img_url = null;
		}
		if (model.img_url_2) {
			aux.img_url_2 = model.img_url_2;
		} else {
			aux.img_url_2 = null;
		}
		if (model.img_url_3) {
			aux.img_url_3 = model.img_url_3;
		} else {
			aux.img_url_3 = null;
		}
		if (model.img_url_4) {
			aux.img_url_4 = model.img_url_4;
		} else {
			aux.img_url_4 = null;
		}
		if (model.img_url_5) {
			aux.img_url_5 = model.img_url_5;
		} else {
			aux.img_url_5 = null;
		}
		aux.travel_id = model.travel_id;
		aux.travel_name = model.travel_name;
		aux.position = model.position;
		aux.address = model.address;
		aux.url = model.url;
		aux.lat = model.lat;
		aux.lon = model.lon;
		aux.relevance = model.relevance;
		aux.tripadvisor = model.tripadvisor;
		aux.status_id = model.status_id;
		aux.type_id = model.type_id;
		aux.features = model.features;
		aux.feature_ids = model.feature_ids;
		aux.provider_ids = model.provider_ids;
		aux.price_ids = model.price_ids;
		aux.prices = model.prices;
		aux.extras = model.extras;
		aux.increase_half_board_activated = model.increase_half_board_activated;
		aux.increase_half_board = model.increase_half_board;
		aux.increase_full_board_activated = model.increase_full_board_activated;
		aux.increase_full_board = model.increase_full_board;
		aux.created_at = model.created_at;
		aux.with_google_reviews = 0;
		return aux;
	}

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;
}
