export default class Travel {
	id: any;
	name: string;
	description: string;
	relevance: any;
	city_id: any;
	lat: any;
	lon: any;
	city_name: string;
	state_id: any;
	state_name: string;
	country_id: any;
	country_name: string;
	season_start: any;
	season_end: any;
	duration_min: any;
	duration_max: any;
	free_teacher_by_students: any;
	supplement: any;
	status_id: boolean;
	tag_ids: any[];
	pdf_cover_img: any;
	pdf_back_cover_img: any;
	created_at: any;

	constructor() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.relevance = null;
		this.city_id = null;
		this.lat = null;
		this.lon = null;
		this.city_name = '';
		this.state_id = null;
		this.state_name = '';
		this.country_id = null;
		this.country_name = '';
		this.season_start = null;
		this.season_end = null;
		this.duration_min = null;
		this.duration_max = null;
		this.free_teacher_by_students = null;
		this.supplement = null;
		this.status_id = false;
		this.tag_ids = [];
		this.pdf_cover_img = null;
		this.pdf_back_cover_img = null;
		this.created_at = null;
	}

	public static copy(model: any) {
		const aux = new Travel();
		aux.id = model.id;
		aux.name = model.name;
		aux.description = model.description;
		aux.relevance = model.relevance;
		aux.city_id = model.city_id;
		aux.lat = model.lat;
		aux.lon = model.lon;
		aux.city_name = model.city_name;
		aux.state_id = model.state_id;
		aux.state_name = model.state_name;
		aux.country_id = model.country_id;
		aux.country_name = model.country_name;
		aux.season_start = model.season_start;
		aux.season_end = model.season_end;
		aux.duration_min = model.duration_min;
		aux.duration_max = model.duration_max;
		aux.free_teacher_by_students = model.free_teacher_by_students;
		aux.supplement = model.supplement;
		aux.status_id = model.status_id;
		aux.tag_ids = model.tag_ids;
		aux.pdf_cover_img = model.pdf_cover_img;
		aux.pdf_back_cover_img = model.pdf_back_cover_img;
		aux.created_at = model.created_at;
		return aux;
	}

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;
}
