
import { Component, Prop, Vue } from 'vue-property-decorator';
import AccommodationExtraPrice from '@/models/accommodation_extra_price';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TextEditor from '@/components/inputs/TextEditor.vue';

@Component({
	components: {
		TextEditor,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class AccommodationExtraPrices extends Vue {
	@Prop({ default: null, required: true }) private extra_prices;

	private prices: AccommodationExtraPrice[] = [];

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	mounted() {
		if (this.extra_prices) {
			this.prices = this.extra_prices;
		}
	}

	addPriceRow() {
		this.prices.push(new AccommodationExtraPrice());
	}

	deletePrice(extraPrice) {
		const index = this.prices.indexOf(extraPrice);
		this.prices.splice(index, 1);
	}

	async validate() {
		this.$emit('input', this.prices);
		return await this.$refs.obs.validate();
	}
}
