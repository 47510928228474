import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Activity from "@/models/activity";

export default class ActivityService {
	private static fields = [
		'name',
		'description',
		'address',
		'lat',
		'lon',
		'img_url',
		'time_type',
		'travel_id',
		'status_id',
		'prices',
		'filters',
		'provider_ids',
		'order_column',
		'availability_start_date',
		'availability_end_date',
	]

	public static get(id) {
		return axios.get(`/activity/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/activity", { params: params });
	}

	public static getSelectorList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/public-activity`, { params: params });
	}

	public static create(activity: Activity) {
		const data = ApiHelper.buildParamsWithFile(this.fields, activity);
		return axios.post('activity', data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static update(activity: Activity) {
		const data = ApiHelper.buildParamsWithFile(this.fields, activity);
		return axios.post(`/activity/${activity.id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static changeStatus(activity: Activity) {
		return axios.get(`/activity/changeStatus/${activity.id}/${activity.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/activity/${id}`);
	}
}
