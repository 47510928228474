export default class ServiceType {
  id: any;
  extra_service_type_id: any;
  name: string;
  img_url: any;


  constructor() {
    this.id = null;
    this.extra_service_type_id = null;
    this.name = '';
    this.img_url = null;
  }

  public static copy(model: any) {
    const aux = new ServiceType();
    aux.id = model.id;
    aux.extra_service_type_id = model.extra_service_type_id;
    aux.name = model.name;
    if (model.img_url) { aux.img_url = model.img_url; }
    else { aux.img_url = null; }
    return aux;
  }
}
