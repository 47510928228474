
	import { Component, Vue } from 'vue-property-decorator';
	import ApiHelper from '@/helpers/api-helper';
	import CountryService from '@/services/CountryService';

	@Component
	export default class CountryFilter extends Vue {
		private items: {}[] = [];
		private filter = null;

		mounted() {
			this.getList();
		}

		getList() {
			CountryService.getSelectorWithTravelsList()
				.then(res => {
					if (res.status === 200) {
						this.prepareOptions(res.data);
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		prepareOptions(data) {
			this.items = [];
			for (const country of data) {
				this.items.push({ text: country.name, value: country.id });
			}
		}
	}
