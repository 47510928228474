
import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionConfirmation from '@/components/modals/ActionConfirmation.vue';
import TravelFilter from '@/components/filters/TravelFilter.vue';
import StatusFilter from '@/components/filters/StatusFilter.vue';
import TransportForm from '@/components/forms/TransportForm.vue';
import Transport from '@/models/transport';
import i18n from '@/plugins/i18n';
import ApiHelper from '@/helpers/api-helper';
import TransportService from '@/services/TransportService';
import FormatHelper from '@/helpers/format-helper';
import StateFilter from '@/components/filters/StateFilter.vue';

	@Component({
		components: {
			StateFilter,
			ActionConfirmation,
			TravelFilter,
			StatusFilter,
			TransportForm,
		},
	})
	export default class TransportTable extends Vue {
		@Watch("pagination")
		onPaginationChanged() {
			this.getList();
		}

	@Watch('filters.search')
	onSearchChanged(value: string) {
		if (!value || value.length === 0) {
			this.getList();
		} else if (value.length >= 3) {
			this.getList();
		}
	}

	$refs!: {
		actionConfirmation: InstanceType<typeof ActionConfirmation>;
	};

	private pagination: any;
	private filters: any;
	private lastFilters: any;
	private loading: boolean;
	private drawer: boolean;
	private edit_id: any;
	private transports: Transport[];
	private total: number;
	private aux_id: any;

	constructor() {
		super();
		this.pagination = {};
		this.filters = {};
		this.lastFilters = {};
		this.filters.status_id = Transport.PUBLISHED.toString();
		this.loading = false;
		this.drawer = false;
		this.edit_id = null;
		this.transports = [];
		this.total = 0;
		this.aux_id = null;
	}

	private headers = [
		{ text: i18n.t('name'), value: 'name', align: 'start', sortable: true },
		{
			text: i18n.t("travel_name"),
			value: "travel_name",
			align: "start",
			sortable: true,
		},
		{
			text: i18n.t("state_name"),
			value: "state",
			align: "start",
			sortable: true,
		},
		{
			text: i18n.t('creation_date'),
			value: 'created_at',
			align: 'start',
			sortable: true,
		},
		{
			text: i18n.t('published'),
			value: 'status_id',
			align: 'start',
			sortable: true,
		},
		{
			text: i18n.t('actions'),
			value: 'actions',
			align: 'end',
			sortable: false,
		},
	];

	getList(force = false) {
		if (ApiHelper.filterHasChanged(this.pagination, this.filters, this.lastFilters) || force) {
			this.loading = true;
			TransportService.list(this.pagination, this.filters)
				.then(res => {
					if (res.status === 200) {
						this.transports = res.data.elements;
						this.total = res.data.total;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	onEdit(id) {
		this.edit_id = id;
		this.drawer = true;
	}

	onDelete(id) {
		this.aux_id = id;
		this.$refs['actionConfirmation'].show();
	}

	confirmDelete() {
		TransportService.delete(this.aux_id)
			.then(res => {
				if (res.status === 204) {
					this.aux_id = null;
					this.getList(true);
					this.$refs['actionConfirmation'].close();
				}
			})
			.catch(error => {
				if (error.response && error.response.status == 503) {
					this.$bus.$emit('error', { error: error, message: error.response.data });
				} else {
					ApiHelper.handleErrors(error, {}, this.$bus);
				}
			});
	}

	dateFormat(date) {
		return FormatHelper.formatDate(date);
	}

	changeStatus(item) {
		const component = this;
		TransportService.changeStatus(item)
			.then(res => {
				if (res.status === 200) {
					this.getList(true);
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
			});
	}
}
