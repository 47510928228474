
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ApiHelper from "@/helpers/api-helper";
	import ProviderService from "@/services/ProviderService";

	@Component({})
	export default class FeatureSelect extends Vue {
		@Prop({ default: 'providers', required: false }) private label;
		@Prop({ default: null, required: false }) private provider_ids;
		@Prop({ default: null, required: false }) private errors;

		private providers: any[] = [];
		private selected: any[] = [];

		mounted() {
			if (this.provider_ids) {
				this.selected = this.provider_ids;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			ProviderService.getSelectorList()
				.then((res) => {
					if (res.status === 200) {
						this.providers = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
