
	import { Component, Prop, Vue } from "vue-property-decorator";
	import TransportTypeService from "@/services/TransportTypeService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class TransportTypeSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: true }) private errors;
		@Prop({ default: null, required: false }) private valid;

		private items: any[] = [];
		private value = null;

		mounted() {
			if (this.model) {
				this.value = this.model;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			TransportTypeService.getSelectorList()
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
