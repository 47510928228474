
	import { Component, Prop, Vue } from "vue-property-decorator";
	import AccommodationPrice from "@/models/accommodation_price";
	import DatePicker from "@/components/inputs/DatePicker.vue";
	import { ValidationObserver, ValidationProvider } from "vee-validate";

	@Component({
		components: {
			DatePicker,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class AccommodationPrices extends Vue {
		@Prop({ default: null, required: true }) private accommodation_prices;
		@Prop({ default: null, required: false }) private errors;

		private prices: AccommodationPrice[] = [];

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		mounted() {
			if (this.accommodation_prices) {
				this.prices = this.accommodation_prices;
			}
		}

		addPriceRow() {
			this.prices.push(new AccommodationPrice());
		}

		deletePrice(accommodationPrice) {
			const index = this.prices.indexOf(accommodationPrice);
			this.prices.splice(index, 1);
		}

		async validate() {
			this.$emit("input", this.prices);
			return await this.$refs.obs.validate();
		}
	}
