import axios from 'axios'

export default class CountryService {

	public static getSelectorList() {
		return axios.get(`/v1/country/filter`);
	}

	public static getSelectorWithTravelsList() {
		return axios.get(`/travel/filter-country`);
	}

}
