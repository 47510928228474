
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import ExtraServiceSelect from "@/components/inputs/ExtraServiceSelect.vue";

	@Component({
		components: {
			ExtraServiceSelect,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TabExtraServicePackage extends Vue {
		@Prop({ default: null, required: true }) private packageExtraService;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: false }) private errors;

		private packageExtraServiceData: any;
		private travelId: any;

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
			extraServiceSelect: InstanceType<typeof ExtraServiceSelect>;
		};

		@Watch("packageExtraService")
		package_extra_service_change(value) {
			this.copyPackageActivity(value);
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.travelId = value;
		}

		constructor() {
			super();
			this.packageExtraServiceData = [];
			this.travelId = null;
		}

		mounted() {
			if(this.packageExtraService){
				this.copyPackageActivity(this.packageExtraService);
			}
			if(this.travel_id){
				this.travelId = this.travel_id;
			}
		}

		copyPackageActivity(data){
			this.packageExtraServiceData = data;
		}

		setPrice(value){
			this.$emit('setPrice', value);
		}

		async validate() {
			this.$refs.extraServiceSelect.setPrice();
			this.$emit("input", this.packageExtraServiceData);
			return await this.$refs.obs.validate();
		}
	}
