
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ProviderService from '@/services/ProviderService';
import Provider from '@/models/provider';
import ApiHelper from '@/helpers/api-helper';

@Component({
	components: {
		ValidationProvider,
		ValidationObserver,
	},
})
export default class ProviderForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	private loading = false;
	private formTitle = '';
	private provider: Provider = new Provider();

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			this.formTitle = 'Edit provider';
			ProviderService.get(this.edit_id)
				.then(res => {
					if (res.status === 200) {
						this.provider = Provider.copy(res.data);
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			this.formTitle = 'New provider';
		}
	}

	async create() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		if (validForm) {
			ProviderService.create(this.provider)
				.then(res => {
					if (res.status === 201) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	async update() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		if (validForm) {
			ProviderService.update(this.provider)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}
}
