
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ExtraServiceSubtypeSelect from '@/components/inputs/ExtraServiceSubtypeSelect.vue';
import ExtraServicePrice from '../../models/extra_service_price';
import TextEditor from '@/components/inputs/TextEditor.vue';

@Component({
	components: {
		TextEditor,
		ValidationProvider,
		ValidationObserver,
		ExtraServiceSubtypeSelect,
	},
})
export default class ExtraServicePrices extends Vue {
	@Prop({ default: null, required: true }) private extra_service_prices;
	@Prop({ default: null, required: true }) private children_type_id;
	@Prop({ default: null, required: false }) private errors;

	private prices: ExtraServicePrice[] = [];
	private type_id: any = [];

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	mounted() {
		if (this.extra_service_prices) {
			this.prices = this.extra_service_prices;
		}
	}

	addPriceRow() {
		this.prices.push(new ExtraServicePrice());
	}

	deletePrice(extraServicePrice) {
		const index = this.prices.indexOf(extraServicePrice);
		this.prices.splice(index, 1);
	}

	async validate() {
		this.$emit('input', this.prices);
		return await this.$refs.obs.validate();
	}
}
