
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import StatusService from '@/services/StatusService';
	import ApiHelper from '@/helpers/api-helper';

    @Component
    export default class StatusFilter extends Vue {
        @Prop({default: '', required: true}) private label;
        @Prop({default: '', required: true}) private model;
        @Prop({default: null, required: false}) private status;

        private items: {}[] = [];
        private filter = null;

        mounted() {
            this.getList();
        }

        getList() {
            StatusService.selectList(this.model).then(
                    res => {
                        if (res.status === 200) {
							this.prepareOptions(res.data);
						}
                    }).catch((error) => {
						ApiHelper.handleErrors(error, {}, this.$bus);
					});
        }

        prepareOptions(data) {
            this.items = [];
            for (const index in data) {
                this.items.push({text: data[index], value: index});
            }
			this.filter = this.status;
        }

    }
