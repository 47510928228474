
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ApiHelper from "@/helpers/api-helper";
	import FilterService from "@/services/FilterService";

	@Component({})
	export default class FilterSelect extends Vue {
		@Prop({ default: null, required: false }) private filter_ids;
		@Prop({ default: null, required: false }) private errors;

		private filters: any[] = [];
		private selected: any[] = [];

		mounted() {
			if (this.filter_ids) {
				this.selected = this.filter_ids;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			FilterService.selectorList()
				.then((res) => {
					if (res.status === 200) {
						this.filters = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
