
import { Component, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import TravelService from '@/services/TravelService';
import ApiHelper from '@/helpers/api-helper';
import ActionConfirmation from '@/components/modals/ActionConfirmation.vue';
import StatusFilter from '@/components/filters/StatusFilter.vue';
import TravelForm from '@/components/forms/TravelForm.vue';
import FormatHelper from '@/helpers/format-helper';
import Travel from '@/models/travel';

@Component({
	components: {
		ActionConfirmation,
		StatusFilter,
		TravelForm,
	},
})
export default class TravelTable extends Vue {
	@Watch('pagination')
	onPaginationChanged(value: string, oldValue: string) {
		this.getList();
	}

	@Watch('filters.search')
	onSearchChanged(value: string, oldValue: string) {
		if (!value || value.length === 0) {
			this.getList();
		} else if (value.length >= 3) {
			this.getList();
		}
	}

	$refs!: {
		actionConfirmation: InstanceType<typeof ActionConfirmation>;
	};

	private pagination: any;
	private filters: any;
	private lastFilters: any;
	private loading: boolean;
	private drawer: boolean;
	private edit_id: any;
	private travels: Travel[];
	private total: number;
	private aux_id = null;

	constructor() {
		super();
		this.pagination = {};
		this.filters = {};
		this.lastFilters = {};
		this.filters.status_id = Travel.PUBLISHED.toString();
		this.loading = false;
		this.drawer = false;
		this.edit_id = null;
		this.travels = [];
		this.total = 0;
		this.aux_id = null;
	}

	private headers = [
		{ text: i18n.t('city'), value: 'city_name', sortable: true },
		{ text: i18n.t('creation_date'), value: 'created_at', sortable: true },
		{ text: i18n.t('published'), value: 'status_id', sortable: true },
		{
			text: i18n.t('actions'),
			value: 'actions',
			sortable: false,
			align: 'end',
		},
	];

	getList(force = false) {
		if (ApiHelper.filterHasChanged(this.pagination, this.filters, this.lastFilters) || force) {
			TravelService.list(this.pagination, this.filters)
				.then(res => {
					if (res.status === 200) {
						this.travels = res.data.elements;
						this.total = res.data.total;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	dateFormat(date) {
		return FormatHelper.formatDate(date);
	}

	deleteTravel(item) {
		this.aux_id = item.id;
		this.$refs['actionConfirmation'].show();
	}

	onEdit(item) {
		this.edit_id = item.id;
		this.drawer = true;
	}

	confirmDelete() {
		TravelService.delete(this.aux_id)
			.then(res => {
				if (res.status === 204) {
					this.aux_id = null;
					this.getList(true);
					this.$refs['actionConfirmation'].close();
				}
			})
			.catch(error => {
				if (error.response && error.response.status == 503) {
					this.$bus.$emit('error', { error: error, message: error.response.data });
				} else {
					ApiHelper.handleErrors(error, {}, this.$bus);
				}
			});
	}

	changeStatus(item) {
		const component = this;
		TravelService.changeStatus(item)
			.then(res => {
				if (res.status === 200) {
					this.getList(true);
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
			});
	}
}
