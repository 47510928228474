
	import { Component, Vue } from "vue-property-decorator";
	import UserService from "@/session/services/UserService";
	import Store from "@/store/index";
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import ApiHelper from "@/helpers/api-helper";

	@Component({
		components: {
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class LoginForm extends Vue {
		$refs!: {
			observer: InstanceType<typeof ValidationObserver>;
		};

		private email = "";
		private password = "";
		private tokenExpiredMessage = "";

		mounted() {
			if (this.$route.params && this.$route.params.tokenExpired) {
				this.tokenExpiredMessage = "token_expired";
			}
		}

		onLogin() {
			const component = this;
			UserService.login(this.email, this.password)
				.then((res) => {
					if (res.status === 200) {
						Store.commit("setToken", res.data.token.token);
						Store.commit("setUser", res.data.user);
						this.$router.replace("/admin/viajes");
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, component.$refs["obs"], this.$bus);
				});
		}

		data() {
			return {
				username: "",
				password: "",
				errorMessages: "Incorrect login info",
				snackbar: false,
				color: "general",
				showPassword: false,
				image_src: require("../assets/img/logo.png"),
				panel_heading: "Viajes Fin de Curso",
			};
		}

		metaInfo() {
			return {
				title: "Login | Dashboard",
			};
		}
	}
