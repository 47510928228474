export default class Province {
  id: any;
  name: string;
  slug: string;
  country_id: any;


  constructor() {
    this.id = null;
    this.name = '';
    this.slug = '';
    this.country_id = null;
  }

  public static copy(model: any) {
    const aux = new Province();
    aux.id = model.id;
    aux.name = model.name;
    aux.slug = model.slug;
    aux.country_id = model.country_id;
    return aux;
  }
}
