<template>
	<v-navigation-drawer id="core-drawer" v-model="drawer" app fixed>
		<v-list>
			<v-list-item>
				<v-img class="brand" :src="logo" height="48" contain/>
			</v-list-item>
			<v-divider/>
			<v-list-item v-for="item in items" :key="item.title" :to="item.to" :disabled="item.disabled">
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		data: () => ({
			logo: require('@/assets/img/logo.png'),
			drawer: true,
			items: [
				{
					to: '/admin/viajes',
					icon: 'mdi-wallet-travel',
					title: 'Viajes',
					disabled: false
				},
				{
					to: '/admin/alojamientos',
					icon: 'mdi-hotel',
					title: 'Alojamientos',
					disabled: false
				},
				{
					to: '/admin/transporte',
					icon: 'mdi-airplane',
					title: 'Transporte',
					disabled: false
				},
				{
					to: '/admin/servicios-extra',
					icon: 'mdi-clipboard-plus',
					title: 'Servicios extra',
					disabled: false
				},
				{
					to: '/admin/actividades',
					icon: 'mdi-table-edit',
					title: 'Actividades',
					disabled: false
				},
				{
					to: '/admin/paquetes',
					icon: 'mdi-package',
					title: 'Paquetes',
					disabled: false
				},
				{
					to: '/admin/proveedores',
					icon: 'mdi-account-arrow-right',
					title: 'Proveedores',
					disabled: false
				},
				{
					to: '/admin/presupuestos',
					icon: 'mdi-receipt',
					title: 'Presupuestos',
					disabled: false
				},
				{
					to: '/admin/configuracion',
					icon: 'mdi-settings',
					title: 'Configuración',
					disabled: false
				}
			]
		})
	};
</script>
