<template>
	<v-app id="dashboard" light>
		<Drawer ref="drawer"></Drawer>

		<Toolbar @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"></Toolbar>

		<transition mode="out-in">
			<v-main>
				<v-container fluid>
					<Alert ref="adminAlert" />
					<router-view />
				</v-container>
			</v-main>
		</transition>

		<!-- <Footer /> -->
	</v-app>
</template>

<script>
	import Drawer from "@/components/core/Drawer.vue";
	import Toolbar from "@/components/core/Toolbar.vue";
	import Alert from "@/components/Alert.vue";
	// import Footer from '@/components/core/Footer.vue'

	export default {
		name: "Dashboard",
		components: {
			Drawer,
			Toolbar,
			Alert
			// Footer
		}
	};
</script>

<style lang="scss">
	@import "@/styles/index.scss";
</style>
