import TransportPrice from "@/models/transport_price";

export default class Transport {

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;

	id: any;
	name: string;
	description: string;
	img_url: any;
	country_id: any;
	travel_id: any;
	state_id: any;
	state: any;
	status_id: any;
	type_id: any;
	prices: TransportPrice[];
	provider_ids: any[];
	travel_ids: any[];


	constructor() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.img_url = null;
		this.country_id = null;
		this.travel_id = null;
		this.state_id = null;
		this.state = null;
		this.status_id = 0;
		this.type_id = null;
		this.prices = [];
		this.provider_ids = [];
		this.travel_ids = [];
	}

	public static copy(model: any) {
		const aux = new Transport();
		aux.id = model.id;
		aux.name = model.name;
		aux.description = model.description;
		aux.img_url = model.img_url;
		aux.country_id = model.country_id;
		aux.travel_id = model.travel_id;
		aux.state_id = model.state_id;
		aux.state = model.state;
		aux.status_id = model.status_id;
		aux.type_id = model.type_id;
		aux.prices = model.prices;
		aux.provider_ids = model.provider_ids;
		aux.travel_ids = model.travel_ids;
		return aux;
	}
}
