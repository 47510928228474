
	import { Component, Vue } from 'vue-property-decorator';
	import TravelService from '@/services/TravelService';
	import ApiHelper from '@/helpers/api-helper';

	@Component
	export default class TravelFilter extends Vue {
		private items: {}[] = [];
		private filter = null;

		mounted() {
			this.getList();
		}

		getList() {
			TravelService.getSelectorList()
				.then(res => {
					if (res.status === 200) {
						this.prepareOptions(res.data);
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		prepareOptions(data) {
			this.items = [];
			for (const travel of data) {
				this.items.push({ text: travel.name, value: travel.id });
			}
		}
	}
