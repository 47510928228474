<template>
	<TravelTable/>
</template>

<script>
	import TravelTable from '@/components/tables/TravelTable'

	export default {
		name: 'Travels',
		components: {
			TravelTable,
		}
	}
</script>
