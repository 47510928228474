import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { localize } from "vee-validate";
import Vuetify from "vuetify";
// import 'vuetify/dist/vuetify.min.css';
// import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "./plugins/vuetify";
import EventBus from "vue-bus-ts";
import './plugins/validation'

Vue.use(EventBus);
const bus = new EventBus.Bus();

Vue.config.productionTip = false;

import i18n from "./plugins/i18n";

localize("es");

Vue.use(Vuetify);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
	function (config) {
		config.headers = {
			Accept: "application/json",
			Authorization: "Bearer " + localStorage.getItem("token"),
			// 'Access-Control-Allow-Origin': 'http://localhost:8080'
		};
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

new Vue({
	router,
	store,
	vuetify,
	i18n,
	bus,
	render: (h) => h(App),
}).$mount("#app");
