
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import ApiHelper from '@/helpers/api-helper';
import BudgetService from '@/services/BudgetService';
import FormatHelper from '@/helpers/format-helper';
import CustomerBudgetPdfForm from '@/components/forms/CustomerBudgetPdfForm.vue';
import CustomerBudgetForm from '@/components/forms/CustomerBudgetForm.vue';
import ActionConfirmation from '@/components/modals/ActionConfirmation.vue';
import Store from '@/store/index'

@Component({
	components: {ActionConfirmation, CustomerBudgetForm, CustomerBudgetPdfForm },
})
export default class CustomerBudgetTable extends Vue {
	@Watch('pagination')
	onPaginationChanged(value: string, oldValue: string) {
		this.getList();
	}

	@Watch('filters.search')
	onSearchChanged(value: string, oldValue: string) {
		if (!value || value.length === 0) {
			this.getList();
		} else if (value.length >= 3) {
			this.getList();
		}
	}

	$refs!: {
		actionConfirmation: InstanceType<typeof ActionConfirmation>;
	};

	private pagination = {};
	private filters = { type: BudgetService.BUDGET_TYPE };
	private lastFilters = {};
	private loading = true;
	private drawer_edition = false;
	private drawer_pdf = false;
	private edit_id = null;
	private aux_id: any;
	private confirmation_type = '';
	private confirmation_message = '';

	private headers = [
		{
			text: i18n.t('reference'),
			sortable: true,
			value: 'id',
			filterable: true,
			align: 'start',
		},
		{
			text: i18n.t('creation_date'),
			value: 'created_at',
			align: 'start',
			sortable: true,
		},
		{
			text: i18n.t('customer'),
			value: 'responsible_name_surname',
			sortable: true,
			align: 'start',
		},
		{
			text: i18n.t('email'),
			value: 'email',
			sortable: true,
			align: 'start',
		},
		{
			text: i18n.t('travel_name'),
			value: 'travel_name',
			sortable: true,
			align: 'start',
		},
		{
			text: i18n.t('total'),
			value: 'total_amount',
			sortable: true,
			align: 'start',
		},
		{
			text: i18n.t('actions'),
			value: 'actions',
			sortable: false,
			align: 'end',
		},
	];
	private budgets: any[] = [];
	private total = 0;

	getList(force = false) {
		if (ApiHelper.filterHasChanged(this.pagination, this.filters, this.lastFilters) || force) {
			this.loading = true;
			BudgetService.list(this.pagination, this.filters)
				.then(res => {
					if (res.status === 200) {
						this.budgets = res.data.elements;
						this.total = res.data.total;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	onEdit(id) {
		this.edit_id = id;
		this.drawer_edition = true;
		document.body.classList.add('drawer-open');
	}

	onEditPDF(id) {
		this.edit_id = id;
		this.drawer_pdf = true;
		document.body.classList.add('drawer-open');
	}

	onDownload(item) {
		const token = Store.getters.getToken;
		window.location.href = `${process.env.VUE_APP_API_URL}/budget/download/${item.id}?token=${token}`;
	}

	onResendEmail(id) {
		this.confirmation_type = 'resend';
		this.confirmation_message = 'Are you sure you want resend this budget?';
		this.aux_id = id;
		this.$refs['actionConfirmation'].show();
	}

	onExternalSync(id) {
		this.confirmation_type = 'sync';
		this.confirmation_message = 'Are you sure you want to sync this budget?';
		this.aux_id = id;
		this.$refs['actionConfirmation'].show();
	}

	onConfirm() {
		if (this.confirmation_type == 'resend'){
			this.resendEmail();
		} else if (this.confirmation_type == 'sync'){
			this.externalSync();
		}
	}

	resendEmail() {
		BudgetService.resendEmail(this.aux_id)
			.then(res => {
				if (res.status === 200) {
					this.$refs['actionConfirmation'].close();
					this.$bus.$emit('success', {
						message: i18n.t('success_resend_email'),
					});
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
	}

	externalSync() {
		BudgetService.externalSync(this.aux_id)
			.then(res => {
				if (res.status === 200) {
					this.$refs['actionConfirmation'].close();
					this.getList(true);
					this.$bus.$emit('success', {
						message: i18n.t('success_external_sync_budget'),
					});
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
	}

	closeDrawer() {
		this.drawer_pdf = false;
		this.drawer_edition = false;
		document.body.classList.remove('drawer-open');
	}

	dateFormat(date) {
		return FormatHelper.formatDate(date);
	}

	priceFormat(price) {
		return FormatHelper.formatPrice(price);
	}
}
