
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ActionConfirmation extends Vue {
  @Prop({ default: "Confirm Action", required: false }) private title;
  @Prop({ default: "", required: true }) private text;

  private dialog = false;

  show() {
    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }
}
