export default class AccommodationPrice {
  id: any;
  date_start: any;
  date_end: any;
  people_min: number;
  people_max: number;
  price: number;
  accommodation_id: any;


  constructor() {
    this.id = null;
    this.date_start = null;
    this.date_end = null;
    this.people_min = 0;
    this.people_max = 0;
    this.price = 0;
    this.accommodation_id = null;
  }

  public static copy(model: any) {
    const aux = new AccommodationPrice();
    aux.id = model.id;
    aux.date_start = model.date_start;
    aux.date_end = model.date_end;
    aux.people_min = model.people_min;
    aux.people_max = model.people_max;
    aux.price = model.price;
    aux.accommodation_id = model.accommodation_id;
    return aux;
  }
}
