
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DayPartSelect from "@/components/inputs/DayPartSelect.vue";

@Component({
	components: {DayPartSelect}
})
export default class ActivitySelect extends Vue {
	@Prop({ default: null, required: false }) private model;
	@Prop({ default: null, required: true }) private day;
	@Prop({ default: null, required: true }) private people;
	@Prop({ default: null, required: true }) private errors;
	@Prop({ default: null, required: false }) private valid;
	@Prop({ default: null, required: true }) private activities;

	private half_day_activities: any[] = [];
	private full_day_activities: any[] = [];
	private items_morning_extras: any[] = [];
	private items_evening_extras: any[] = [];
	private items_full_day_extras: any[] = [];

	private value = {
		daypart: null,
		day_part_1: {
			day: null,
			people: null,
			daypart: 1,
			activity_id: null,
			budget_activity_id: null,
			prices: [],
			prices_to_display: [],
			extras: []
		},
		day_part_2: {
			day: null,
			people: null,
			daypart: 2,
			activity_id: null,
			budget_activity_id: null,
			prices: [],
			prices_to_display: [],
			extras: []
		},
		day_part_3: {
			day: null,
			people: null,
			daypart: 3,
			activity_id: null,
			budget_activity_id: null,
			prices: [],
			prices_to_display: [],
			extras: []
		}
	};

	// @Watch("model")
	// model_change(value) {
	// 	this.value = value;
	// }

	@Watch("people")
	people_change(value) {
		this.value.day_part_1.people = value;
		this.value.day_part_2.people = value;
		this.value.day_part_3.people = value;
	}

	@Watch("activities")
	activities_change(values) {
		this.prepareOptions();
	}

	constructor() {
		super();
	}

	mounted() {
		if (this.activities.length) {
			this.prepareOptions();
		}
		if (this.model.daypart) {
			this.copy();
		} else {
			this.reset();
		}
	}

	reset() {
		this.value = {
			daypart: null,
			day_part_1: {
				day: this.day,
				people: this.people,
				daypart: 1,
				activity_id: null,
				budget_activity_id: null,
				prices_to_display: [],
				prices: [],
				extras: []
			},
			day_part_2: {
				day: this.day,
				people: this.people,
				daypart: 2,
				activity_id: null,
				budget_activity_id: null,
				prices_to_display: [],
				prices: [],
				extras: []
			},
			day_part_3: {
				day: this.day,
				people: this.people,
				daypart: 3,
				activity_id: null,
				budget_activity_id: null,
				prices_to_display: [],
				prices: [],
				extras: []
			}
		};
	}

	copy() {
		this.value = this.model;
		if (this.value.day_part_1.activity_id) {
			this.setExtrasAndPrices('day_part_1', 'morning');
		}
		if (this.value.day_part_2.activity_id) {
			this.setExtrasAndPrices('day_part_2', 'evening');
		}
		if (this.value.day_part_3.activity_id) {
			this.setExtrasAndPrices('day_part_3');
		}
		// this.emitValue(this.value);
	}


	setExtrasAndPrices(day_part, morning_or_evening = ''){
		let extras_to_apply = '';
		if (day_part == 'day_part_3') {
			extras_to_apply = 'full_day';
			this.items_full_day_extras = [];
		} else if (morning_or_evening == 'morning') {
			extras_to_apply = 'morning';
			this.items_morning_extras = [];
		}else if (morning_or_evening == 'evening') {
			extras_to_apply = 'evening';
			this.items_evening_extras = [];
		}
		this.value[day_part].prices = [];
		for (const activity of this.activities) {
			if (activity.id == this.value[day_part].activity_id) {
				this.value[day_part].prices_to_display = activity.prices;
				for (const price of activity.prices) {
					this.value[day_part].prices.push(price.id);
				}
				if (extras_to_apply == 'full_day') {
					this.items_full_day_extras = activity.extras;
				} else if (extras_to_apply == 'morning') {
					this.items_morning_extras = activity.extras;
				}else if (extras_to_apply == 'evening') {
					this.items_evening_extras = activity.extras;
				}
			}
		}
	}

	clearDayParts() {
		const currentDayPart = this.value.daypart;
		this.reset();
		this.value.daypart = currentDayPart;
		this.emitValue(this.value)
	}

	prepareOptions() {
		this.half_day_activities = [];
		this.full_day_activities = [];
		for (const activity of this.activities) {
			if (activity.time_type == 1) {
				this.half_day_activities.push({'id': activity.id, 'name': activity.name});
			}else{
				this.full_day_activities.push({'id': activity.id, 'name': activity.name});
			}
		}
	}

	emitValue(value){
		this.$emit('input', value);
		this.$emit('setPrice', value);
	}
}
