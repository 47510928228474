
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TravelSelect from '@/components/inputs/TravelSelect.vue';
import ExtraServicePrices from '@/components/forms/ExtraServicePrices.vue';
import ExtraServiceService from '@/services/ExtraService';
import ExtraService from '@/models/extra_service';
import ApiHelper from '@/helpers/api-helper';
import ExtraServiceTypeSelect from '@/components/inputs/ExtraServiceTypeSelect.vue';
import TextEditor from '@/components/inputs/TextEditor.vue';
import ProviderSelect from '@/components/inputs/ProviderSelect.vue';

@Component({
	components: {
		ProviderSelect,
		TextEditor,
		TravelSelect,
		ExtraServicePrices,
		ExtraServiceTypeSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class ExtraServiceForm extends Vue {
	@Prop({ default: null, required: false }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
		extraServicePrice: InstanceType<typeof ExtraServicePrices>;
	};

	private loading = false;
	private formTitle = '';
	private extra_service: ExtraService = new ExtraService();

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			this.formTitle = 'Edit extra service';
			ExtraServiceService.get(this.edit_id).then(res => {
				if (res.status === 200) {
					this.extra_service = res.data;
					this.loading = false;
				}
			});
		} else {
			this.formTitle = 'New extra service';
		}
	}

	async create() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['extraServicePrice'].validate();
		if (validForm && validPrices) {
			ExtraServiceService.create(this.extra_service)
				.then(res => {
					if (res.status === 201) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	async update() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['extraServicePrice'].validate();
		if (validForm && validPrices) {
			ExtraServiceService.update(this.extra_service)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}
}
