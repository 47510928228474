import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/src/locale/es';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		options: {
		customProperties: true,
		},
		themes: {
			light: {
			primary: '#29CDED',
			secondary: '#424242',
			accent: '#4CDDCE',
			error: '#FF5252',
			info: '#2196F3',
			success: '#4CAF50',
			warning: '#FFC107'
			},
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
});
