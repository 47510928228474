
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TransportService from "@/services/TransportService";
import ApiHelper from "@/helpers/api-helper";
import {ValidationObserver, ValidationProvider} from 'vee-validate';

	@Component({
		components: {
			ValidationProvider,
			ValidationObserver
		}
	})
	export default class TransportSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;
		@Prop({ default: null, required: false }) private valid;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: false }) private state_id;
		@Prop({ default: null, required: true }) private people;
		@Prop({ default: null, required: true }) private days;

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		private items: any[] = [];
		private total = 0;
		private selected = {};
		private value: {
			id: null;
			budget_transport_id: null;
			prices: any[];
			price_ids: any[];
			extras: any[];
			extra_ids: any[];
		};
		private filters: any;

		@Watch("model")
		model_change(value) {
			this.value = value;
			if (this.value.id) {
				this.$emit('setPrice', this.value);
			}
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.filters.travel_id = value;
			this.getSelectorList();
		}

		@Watch("state_id")
		state_id_change(value) {
			this.filters.state_id = value;
			this.getSelectorList();
		}

		@Watch("days")
		days_change(value) {
			this.filters.days = value;
			this.getSelectorList();
		}

		@Watch("people")
		people_change(value) {
			this.filters.people = value;
			this.getSelectorList();
		}

		constructor() {
			super();
			this.filters = { travel_id: null, days: null, people: null, state_id: null };
			this.value = {
				id: null,
				budget_transport_id: null,
				prices: [],
				price_ids: [],
				extras: [],
				extra_ids: [],
			};
		}

		mounted() {
			if (this.travel_id) {
				this.filters.travel_id = this.travel_id;
			}
			if (this.state_id) {
				this.filters.state_id = this.state_id;
			}
			if (this.days) {
				this.filters.days = this.days;
			}
			if (this.model.id) {
				this.value = this.model;
				this.emitValue();
			}
			if (this.people != null) {
				this.filters.people = this.people;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			if (this.filters.days && this.filters.travel_id && this.filters.people != null) {
				TransportService.getSelectorList(null, this.filters)
					.then((res) => {
						if (res.status === 200) {
							this.items = res.data.elements;
							this.items.push({
								id: null,
								name:'Sin Transporte',
								extras: [],
								prices: []
							})
							if (this.value.id) {
								this.setSelected(false);
							}
						}
					})
					.catch((error) => {
						ApiHelper.handleErrors(error, {}, this.$bus);
					});
			}
		}

		totalPrice(){
			let total = 0;
			this.value.price_ids = [];
			if(this.value.id && this.value.prices){
				for (const price of this.value.prices) {
					this.value.price_ids.push(price['id']);
					total += price['price'];
				}
			}
			this.total = total;
		}

		setSelected(clear_extras = true) {
			this.selected = {};
			if (clear_extras) {
				this.value.extras = [];
				this.value.extra_ids = [];
			}
			for (const transport of this.items) {
				if (this.value.id == transport.id) {
					this.selected = transport;
					this.value.prices = transport.prices
					this.totalPrice()
					break;
				}
			}
		}

		setExtras(){
			this.value.extras = [];
			for (const extra of this.selected['extras']) {
				if (this.value.extra_ids.indexOf(extra['id'])  > -1) {
					this.value.extras.push(extra);
				}
			}
		}

		emitValue(){
			this.$emit('input', this.value);
			this.$emit('setPrice', this.value);
		}

		async validate() {
			return await this.$refs.obs.validate();
		}
	}
