import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Accomodation from "@/models/accommodation";

export default class AccomodationService {

	public static BOARD_BASIC_TYPE_ID = 1;
	public static BOARD_HALF_TYPE_ID = 2;
	public static BOARD_FULL_TYPE_ID = 3;

	private static fields = [
		'name',
		'description',
		'img_url',
		'img_url_2',
		'img_url_3',
		'img_url_4',
		'img_url_5',
		'travel_id',
		'position',
		'address',
		'url',
		'lat',
		'lon',
		'relevance',
		'tripadvisor',
		'increase_half_board_activated',
		'increase_half_board',
		'increase_full_board_activated',
		'increase_full_board',
		'status_id',
		'type_id',
		'features',
		'prices',
		'extras',
		'provider_ids',
		'with_google_reviews',
	];

	public static get(id) {
		return axios.get(`/accommodation/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/accommodation", { params: params });
	}

	public static getSelectorList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/accommodation/filter`, { params: params });
	}

	public static getSelectorTypeList() {
		return axios.get(`/accommodation_type/filter`);
	}

	public static getSelectorFeatureList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/feature/filter`, { params: params });
	}

	public static getSelectorPriceList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/accommodation_price/filter`, { params: params });
	}

	public static getSelectorPriceListGroup(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/accommodation_price/filter-group`, { params: params });
	}

	public static create(accommodation: Accomodation) {
		const data = ApiHelper.buildParamsWithFile(this.fields, accommodation);
		return axios.post('accommodation', data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static update(accommodation: Accomodation) {
		const data = ApiHelper.buildParamsWithFile(this.fields, accommodation);
		return axios.post(`/accommodation/${accommodation.id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static changeStatus(accommodation: Accomodation) {
		return axios.get(`/accommodation/changeStatus/${accommodation.id}/${accommodation.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/accommodation/${id}`);
	}
}
