<template>
	<v-app-bar
		id="core-toolbar"
		color="accent"
		light
		dense
		flat
		app
		>
		<v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')" />
		<BreadCrumbs />
		<v-spacer></v-spacer>
		<v-menu
			top
			right
			>
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on">
					<v-icon>mdi-account-circle</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item @click="() => {}">
					<v-list-item-title><v-icon>mdi-account</v-icon> Mi perfil</v-list-item-title>
				</v-list-item>
				<v-list-item id="#logout" v-on:click="logout">
					<v-list-item-title><v-icon>mdi-logout</v-icon> Salir</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</v-app-bar>
</template>

<script>
import BreadCrumbs from '@/components/core/BreadCrumbs.vue'

export default {
	components: {
		BreadCrumbs
	},
	methods: {
		logout: function () {
			// FIXME esto solo es una redireccion, no hace logout
			this.$router.replace('/')
		}
	}
}

// TODO vista de edicion de usuario
</script>
