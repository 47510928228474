
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import ApiHelper from '@/helpers/api-helper';
	import ProvinceService from '@/services/ProvinceService';

	@Component
	export default class StateFilter extends Vue {
		@Prop({default: null, required: false}) private country_id;
		@Prop({default: false, required: false}) private disabled;

		@Watch('country_id')
		country_id_change(value: string) {
			this.country_id = value;
			this.filterBy = 'country_id';
			this.getList();
		}

		@Watch('disabled')
		disabled_change(value: string) {
			this.disabled = value;
			this.$forceUpdate();
		}

		private items: {}[] = [];
		private filter = null;

		private filterBy = '';

		mounted() {
			this.getList();
		}

		getList() {
			ProvinceService.getSelectorList(this.country_id).then(
				res => {
					if (res.status === 200) {
						this.prepareOptions(res.data);
					}
				}).catch((error) => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
		}

		prepareOptions(data) {
			this.items = [];
			for (const state of data) {
				this.items.push({text: state.name, value: state.id});
			}
		}
	}
