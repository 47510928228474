
import { Component, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';
import ActionConfirmation from '@/components/modals/ActionConfirmation.vue';
import PackageForm from '@/components/forms/PackageForm.vue';
import StatusFilter from '@/components/filters/StatusFilter.vue';
import TravelFilter from '@/components/filters/TravelFilter.vue';
import PackageService from '@/services/PackageService';
import ApiHelper from '@/helpers/api-helper';
import FormatHelper from '@/helpers/format-helper';
import Package from '@/models/package';

@Component({
	components: {
		ActionConfirmation,
		PackageForm,
		StatusFilter,
		TravelFilter,
	},
})
export default class PackagesTable extends Vue {
	@Watch('pagination')
	onPaginationChanged(value: string, oldValue: string) {
		this.getList(false);
	}

	@Watch('filters.search')
	onSearchChanged(value: string) {
		if (!value || value.length === 0) {
			this.getList();
		} else if (value.length >= 3) {
			this.getList();
		}
	}

	$refs!: {
		actionConfirmation: InstanceType<typeof ActionConfirmation>;
	};

	private pagination: any;
	private filters: any;
	private lastFilters: any;
	private loading: boolean;
	private drawer: boolean;
	private edit_id: any;
	private packages: Package[];
	private total: number;
	private aux_id: any;

	constructor() {
		super();
		this.pagination = {};
		this.filters = {};
		this.lastFilters = {};
		this.filters.status_id = Package.PUBLISHED.toString();
		this.loading = false;
		this.drawer = false;
		this.edit_id = null;
		this.packages = [];
		this.total = 0;
		this.aux_id = null;
	}

	private headers = [
		{ text: i18n.t('name'), value: 'name', sortable: true },
		{ text: i18n.t('travel_id'), value: 'travel_name', sortable: true },
		{ text: i18n.t('total'), value: 'total_amount', sortable: true },
		{ text: i18n.t('creation_date'), value: 'created_at', sortable: true },
		{ text: i18n.t('status'), value: 'status_id', sortable: true },
		{ text: i18n.t('offer'), value: 'offer', sortable: true },
		{ text: i18n.t('offer_start_date'), value: 'offer_start_date', sortable: true },
		{ text: i18n.t('offer_end_date'), value: 'offer_end_date', sortable: true },
		{ text: i18n.t('actions'), value: 'actions', align: 'end', sortable: false },
	];

	getList(force = false) {
		if (ApiHelper.filterHasChanged(this.pagination, this.filters, this.lastFilters) || force) {
			PackageService.list(this.pagination, this.filters)
				.then(res => {
					if (res.status === 200) {
						this.packages = res.data.elements;
						this.total = res.data.total;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	deletePackage(item) {
		this.aux_id = item.id;
		this.$refs['actionConfirmation'].show();
	}

	confirmDelete() {
		PackageService.delete(this.aux_id)
			.then(res => {
				if (res.status === 204) {
					this.aux_id = null;
					this.getList(true);
					this.$refs['actionConfirmation'].close();
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
	}

	onEdit(item) {
		this.edit_id = item.id;
		this.drawer = true;
	}

	dateFormat(date) {
		return FormatHelper.formatDate(date);
	}

	priceFormat(price) {
		return FormatHelper.formatPrice(price);
	}

	changeStatus(item) {
		const component = this;
		PackageService.changeStatus(item)
			.then(res => {
				if (res.status === 200) {
					this.getList(true);
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
			});
	}
}
