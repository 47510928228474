
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ApiHelper from "@/helpers/api-helper";
	import SelectService from "@/services/SelectService";

	@Component({})
	export default class WhoSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;

		private items: {}[] = [];
		private selected: any = null;

		mounted() {
			if (this.model) {
				this.selected = this.model;
			}
			this.getSelectorList();
		}

		getSelectorList() {
			SelectService.selectList("who")
				.then((res) => {
					if (res.status === 200) {
						this.prepareOptions(res.data);
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		prepareOptions(data) {
			this.items = [];
			for (const element of data) {
				this.items.push({ text: element["name"], value: element["id"] });
			}
		}
	}
