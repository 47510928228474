
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import StatusService from '@/services/StatusService';

	@Component
	export default class Alert extends Vue {
		@Prop({default: 5000, required: false}) private timeout;

		private snackbar = false;
		private message = '';
		private type = '';

		created() {
			const component = this;
			this.$bus.$on('error', function (params) {
				component.type = 'error';
				component.message = params.message;
				component.snackbar = true;
			})
			this.$bus.$on('success', function (params) {
				component.type = 'success';
				component.message = params.message;
				component.snackbar = true;
			})

		}

	}
