import ExtraServicePrice from "@/models/extra_service_price";

export default class ExtraService {

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;

	id: any;
	name: string;
	description: string;
	discount: any;
	discount_amount: any;
	travel_id: any;
	status_id: any;
	children_type_id: any;
	url: string;
	extra_services_prices: ExtraServicePrice[];
	provider_ids: any[];
	created_at: any;

	constructor() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.discount = 0;
		this.discount_amount = 0;
		this.travel_id = null;
		this.status_id = 0;
		this.extra_services_prices = [];
		this.url = '';
		this.provider_ids = [];
		this.children_type_id = null;
		this.created_at = null;
	}

	public static copy(model: any) {
		const aux = new ExtraService();
		aux.id = model.id;
		aux.name = model.name;
		aux.description = model.description;
		aux.discount = model.discount;
		aux.discount_amount = model.discount_amount;
		aux.travel_id = model.travel_id;
		aux.status_id = model.status_id;
		aux.url = model.url;
		aux.extra_services_prices = model.extra_services_prices;
		aux.provider_ids = model.provider_ids;
		aux.children_type_id = model.children_type_id;
		aux.created_at = model.created_at;
		return aux;
	}
}
