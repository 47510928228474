
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import AccomodationService from "@/services/AccomodationService";
	import ApiHelper from "@/helpers/api-helper";
import FormatHelper from "@/helpers/format-helper";

	@Component({})
	export default class AccommodationPriceSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: true }) private errors;
		@Prop({ default: null, required: true }) private accommodation_id;
		@Prop({ default: null, required: true }) private feature;
		@Prop({ default: null, required: true }) private increases;
		@Prop({ default: null, required: true }) private people;

		private items: any[] = [];
		private aux_items: any[] = [];
		private value = null;
		private filters: any;

		@Watch("accommodation_id")
		accommodation_id_change(value) {
			this.filters.accommodation_id = value;
			if (this.filters.accommodation_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		@Watch("people")
		people_change(value) {
			this.filters.people = value;
			if (this.filters.accommodation_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		@Watch("feature")
		feature_change() {
			this.prepareItems(this.aux_items)
		}

		constructor() {
			super();
			this.filters = { accommodation_id: null, people: null };
		}

		mounted() {
			if (this.model) {
				this.value = this.model;
			}
			if (this.accommodation_id) {
				this.filters.accommodation_id = this.accommodation_id;
			}
			if (this.people != null) {
				this.filters.people = this.people;
			}
			if (this.filters.accommodation_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		getSelectorList() {
			AccomodationService.getSelectorPriceListGroup(null, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.prepareItems(res.data);
						this.aux_items = res.data;
						if(this.value){
							for (const accommodation of this.items) {
								if(accommodation.id == this.value){
									this.$emit('setPrice', accommodation);
								}
							}
						}
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		emitValue(value){
			this.$emit('input', value.id);
			this.$emit('setPrice', value)
		}

		prepareItems(itemsData){
			this.items = [];
			const items: any[] = [];
			let date = ''
			for (const itemKey in itemsData){
				for (const itemData of itemsData[itemKey]){
					const price = this.feature == 1 ? itemData['price'] :
						(this.feature == 2 ? itemData['price'] + this.increases.half_board : itemData['price'] + this.increases.full_board );
					if(date == itemKey){
						items.push({
							name: 'De ' + itemData['people_min'] + ' a ' + itemData['people_max'] + ' personas ' + price + ' €',
							id: itemData['id'],
							price: price,
							people: itemData['people_max']
						});
					}else{
						date = itemKey;
						items.push({header: 'Desde ' + FormatHelper.formatDate(itemKey) + ' hasta el ' + FormatHelper.formatDate(itemData['date_end'])});
						items.push({
							name: 'De ' + itemData['people_min'] + ' a ' + itemData['people_max'] + ' personas ' + price + ' €',
							id: itemData['id'],
							price: price,
							people: itemData['people_max']
						});
					}
				}
			}
			this.items = items;
		}
	}
