import i18n, {addLoadedLang, setI18nLanguage} from '@/plugins/i18n'
import axios from 'axios'
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/en.json';

export const langResolver = (to, from, next) => {
	if (Object.keys(i18n.messages).length) {
		next();
	}else{
		return axios.get('/translation/' + i18n.locale).then(
			res => {
				if (res.status === 200) {
					const data = res.data;
					data.validations = i18n.locale == 'es' ? es.messages : en.messages;
					i18n.setLocaleMessage(i18n.locale, data)
					addLoadedLang(i18n.locale)
					setI18nLanguage(i18n.locale)
					next();
				}
			}
		).catch((error) => {
			next();
		});
	}
}
