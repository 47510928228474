import axios from 'axios';

export default class FeatureService {
	public static BOARD_TYPE = 1;
	public static ACCOMODATION_TYPE = 2;
	public static ACOMMODATION_CENTER_DISTANCE_TYPE = 3;

	public static selectorList(type) {
		return axios.get(`/feature/filter?type=${type}`);
	}
}
