
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TravelSelect from '@/components/inputs/TravelSelect.vue';
import ActivityService from '@/services/ActivityService';
import Activity from '@/models/activity';
import ApiHelper from '@/helpers/api-helper';
import ImageUpload from '@/components/inputs/ImageUpload.vue';
import ActivityPrices from '@/components/inputs/ActivityPrices.vue';
import FilterSelect from '@/components/inputs/FilterSelect.vue';
import ActivityTimeSelect from '@/components/inputs/ActivityTimeSelect.vue';
import TextEditor from '@/components/inputs/TextEditor.vue';
import ProviderSelect from '@/components/inputs/ProviderSelect.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';

@Component({
	components: {
		DatePicker,
		ProviderSelect,
		TextEditor,
		ActivityTimeSelect,
		FilterSelect,
		ActivityPrices,
		ImageUpload,
		TravelSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class ActivityForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
		activityPrices: InstanceType<typeof ActivityPrices>;
	};

	private loading = false;
	private formTitle = '';
	private activity: Activity = new Activity();

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			this.formTitle = 'Edit activity';
			ActivityService.get(this.edit_id)
				.then(res => {
					if (res.status === 200) {
						this.activity = res.data;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			this.formTitle = 'New activity';
		}
	}

	async validateFile($event) {
		await this.$refs['img_url'].validate($event);
	}

	async create() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['activityPrices'].validate();
		if (validForm && validPrices) {
			ActivityService.create(this.activity)
				.then(res => {
					if (res.status === 201) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	async update() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['activityPrices'].validate();
		if (validForm && validPrices) {
			ActivityService.update(this.activity)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	get isImageUploaded() {
		return typeof this.activity.img_url === 'string';
	}
}
