
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import ActivitySelect from '@/components/inputs/ActivitySelect.vue';
	import ActivityService from '@/services/ActivityService';
	import ApiHelper from '@/helpers/api-helper';

	@Component({
		components: {
			ActivitySelect,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TabActivityPackage extends Vue {
		@Prop({ default: null, required: true }) private packageActivity;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: true }) private days;
		@Prop({ default: null, required: true }) private people;
		@Prop({ default: null, required: false }) private errors;

		private packageActivityData: any;
		private travelId: any;
		private daysData: any;
		private activities: any[] = [];

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		@Watch('people')
		people_change(value) {
			this.setDaysData();
			this.setPrice();
			if (this.people && this.travel_id) {
				this.getSelectorList();
			}
		}

		@Watch('days')
		days_change(value) {
			this.setDaysData();
		}

		@Watch('packageActivity')
		package_activity_change(value) {
			this.copyPackageExtraService(value);
		}

		@Watch('travel_id')
		travel_id_change(value) {
			this.travelId = value;
			if (this.people && this.travel_id) {
				this.getSelectorList();
			}
		}

		constructor() {
			super();
			this.packageActivityData = [];
			this.travelId = null;
			this.daysData = 0;
		}

		mounted() {
			if (this.packageActivity.length) {
				this.copyPackageExtraService(this.packageActivity);
			}
			if (this.days) {
				this.setDaysData();
			}
			if (this.travel_id) {
				this.travelId = this.travel_id;
			}
			if (this.people) {
				this.setPrice();
			}
			if (this.people && this.travel_id) {
				this.getSelectorList();
			}
		}

		getSelectorList() {
			ActivityService.getSelectorList(null, { travel_id: this.travel_id, people: this.people })
				.then(res => {
					if (res.status === 200) {
						this.activities = res.data.elements;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		copyPackageExtraService(data) {
			this.packageActivityData = data;
		}

		setDaysData() {
			this.daysData = [];
			for (let i = 0; i < this.days; i++) {
				let existDay = null;
				for (const activity of this.packageActivityData) {
					if (activity.day == i) {
						existDay = activity;
					}
				}
				if (existDay !== null) {
					this.daysData.push(this.copy(existDay));
				} else {
					this.daysData.push({});
				}
			}
			// this.setPrice();
		}

		setPrice() {
			let total = 0;
			for (const day of this.daysData) {
				if (day.day_part_1 && day.day_part_1.activity_id) {
					total += this.getPricesAndExtras(day.day_part_1);
				}
				if (day.day_part_2 && day.day_part_2.activity_id) {
					total += this.getPricesAndExtras(day.day_part_2);
				}
				if (day.day_part_3 && day.day_part_3.activity_id) {
					total += this.getPricesAndExtras(day.day_part_3);
				}
			}
			this.$emit('setPrice', total);
		}

		getPricesAndExtras(day) {
			let total = 0;
			for (const price of day.prices_to_display) {
				if (price.group) {
					total += Math.round((price.price / this.people) * 100) / 100;
				} else {
					total += price.price;
				}
			}
			for (const extra of day.extras) {
				if (extra.group) {
					total += Math.round((extra.price / this.people) * 100) / 100;
				} else {
					total += extra.price;
				}
			}
			return total;
		}

		async validate() {
			this.$emit('input', this.daysData);
			return await this.$refs.obs.validate();
		}

		copy(model) {
			return {
				daypart: model.daypart,
				day_part_1: {
					day: model.day_part_1.day,
					people: this.people,
					daypart: 1,
					activity_id: model.day_part_1.activity_id,
					budget_activity_id: model.day_part_1.budget_activity_id,
					prices_to_display: model.day_part_1.prices_to_display,
					prices: model.day_part_1.prices,
					extras: model.day_part_1.extras,
				},
				day_part_2: {
					day: model.day_part_2.day,
					people: this.people,
					daypart: 2,
					activity_id: model.day_part_2.activity_id,
					budget_activity_id: model.day_part_2.budget_activity_id,
					prices_to_display: model.day_part_2.prices_to_display,
					prices: model.day_part_2.prices,
					extras: model.day_part_2.extras,
				},
				day_part_3: {
					day: model.day_part_3.day,
					people: this.people,
					daypart: 3,
					activity_id: model.day_part_3.activity_id,
					budget_activity_id: model.day_part_3.budget_activity_id,
					prices_to_display: model.day_part_3.prices_to_display,
					prices: model.day_part_3.prices,
					extras: model.day_part_3.extras,
				},
			};
		}
	}
