import axios from 'axios';

export default class TravelService {

	public static get(id) {
		return axios.get(`/tag/${id}`);
	}

	public static selectorList() {
		return axios.get("/tag");
	}
}
