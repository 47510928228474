import router from '../router'
import i18n from '@/plugins/i18n'

export default class ApiHelper {

	public static setQueryParams(pagination, filters) {
		const params = new URLSearchParams();
		if (pagination) {
			params.append('page', pagination.page);
			params.append('pagination', pagination.itemsPerPage);
			if (pagination.sortBy.length) {
				if (pagination.sortDesc.length && pagination.sortDesc[0]) {
					params.append('sort', '-' + pagination.sortBy[0]);
				} else {
					params.append('sort', pagination.sortBy[0]);
				}
			}
		}
		if (Object.keys(filters).length) {
			Object.keys(filters).forEach(key => {
				if (filters[key] !== undefined && filters[key] !== null) {
					params.append(key, filters[key]);
				}
			});
		}
		return params;
	}

	public static  buildParams(fields, model): any {
		const params = {};
		for (const field of fields) {
			if (model[field] != undefined && !Array.isArray(model[field]) && typeof model[field] !== 'object') {
				params[field] = model[field];
			} else if (model[field] != undefined && (Array.isArray(model[field]) || typeof model[field] === 'object')) {
				params[field] = JSON.stringify(model[field]);
			}
		}
		return params;
	}

	public static  buildParamsWithFile(fields, model): any {
		const formData = new FormData();
		for (const field of fields) {
			if (model[field] != undefined && !Array.isArray(model[field]) && (typeof model[field] !== 'object' || typeof model[field].lastModified  === 'number')) {
				formData.append(field, model[field]);
			} else if (model[field] != undefined && (Array.isArray(model[field]) || typeof model[field] === 'object')) {
				formData.append(field, JSON.stringify(model[field]));
			}
		}
		return formData;
	}

	public static handleErrors(error, formRef = {}, bus) {
		if (error.response && error.response.status == 500) {
			let message = '';
			if (error.config.method == 'get') {
				message = 'error_get_message';
			} else if (error.config.method == 'post' || error.config.method == 'put'){
				message = 'error_post_message';
			}
			if (bus) {
				bus!.$emit('error', {error: error, message: i18n.t(message)});
			}
		}
		if (error.response && error.response.status == 502) {
			if (bus) {
				bus!.$emit('error', {error: error, message: i18n.t('not_allowed')});
			}
		}
		if (error.response && error.response.status == 404) {
			if (bus) {
				bus!.$emit('error', {error: error, message: i18n.t('not_found')});
			}
		}
		if (error.response && error.response.status == 401 && error.response.data == 'invalid_credentials') {
			formRef['errors'].email.push(i18n.t('invalid_credentials'));
		}
		if (error.response && error.response.status == 405 && error.response.data == 'activate_account') {
			formRef['errors'].email.push(i18n.t('activate_account'));
		}
		if (error.response && error.response.status == 405 && error.response.data == 'disabled_account') {
			formRef['errors'].email.push(i18n.t('disabled_account'));
		}
		if (error.response && error.response.status == 401 && error.response.data.error == 'token_expired') {
			localStorage.setItem('token_expired', '1');
			router.replace('/');
		}
		if (error.response && error.response.status == 450 && Object.keys(formRef).length) {
			this.handleValitationErrors(formRef, error.response.data)
		}
	}

	public static handleValitationErrors(ref, errorsFields) {
		Object.keys(errorsFields).forEach(key => {
			if (ref.errors[key]) {
				ref.errors[key].push(errorsFields[key][0])
			} else {
				ref.errors[key] = [];
				ref.errors[key].push(errorsFields[key][0]);
			}
		});
		return ref;
	}

	public static filterHasChanged(pagination, filter, lastFilter): boolean {
		// Si ha cambiado la paginación
		if (pagination && pagination.page && (!Object.keys(lastFilter).length || lastFilter.pagination.page != pagination.page) ||
			// Si ha cambiado el número de elementos por página
			(pagination && pagination.itemsPerPage && (!Object.keys(lastFilter).length || lastFilter.pagination.itemsPerPage != pagination.itemsPerPage) ||
				// Si han cambiado los filtros
				(!Object.keys(lastFilter).length || JSON.stringify(filter) != JSON.stringify(lastFilter.filter)) ||
				// Si ha cambiado la ordenación
				(pagination && pagination.sortBy &&
					(!Object.keys(lastFilter).length || JSON.stringify(lastFilter.pagination.sortBy) != JSON.stringify(pagination.sortBy)
						|| JSON.stringify(lastFilter.pagination.sortDesc) != JSON.stringify(pagination.sortDesc))))) {
			lastFilter.pagination = JSON.parse(JSON.stringify(pagination));
			lastFilter.filter = JSON.parse(JSON.stringify(filter));
			return  true;
		}
		return false;
	}
}
