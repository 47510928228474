
	import { Component, Prop, Vue } from "vue-property-decorator";
	import TransportPrice from "@/models/transport_price";
	import { ValidationObserver, ValidationProvider } from "vee-validate";

	@Component({
		components: {
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TransportPrices extends Vue {
		@Prop({ default: null, required: true }) private transport_prices;
		@Prop({ default: null, required: false }) private errors;

		private prices: TransportPrice[] = [];
		private price_types: {}[] = [
			{ text: "€/km", value: "km" },
			{ text: "€/día", value: "daily" },
			{ text: "€", value: "base" },
		];

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		mounted() {
			if (this.transport_prices) {
				this.prices = this.transport_prices;
			}
		}

		addPriceRow() {
			this.prices.push(new TransportPrice());
		}

		deletePrice(transportPrice) {
			const index = this.prices.indexOf(transportPrice);
			this.prices.splice(index, 1);
		}

		async validate() {
			this.$emit("input", this.prices);
			return await this.$refs.obs.validate();
		}
	}
