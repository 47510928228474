
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ActivitySelect from '@/components/inputs/ActivitySelect.vue';
import ActivityService from '@/services/ActivityService';
import ApiHelper from '@/helpers/api-helper';
import Budget from '@/models/budget';

@Component({
	components: {
		ActivitySelect,
	},
})
export default class TabBudgetActivities extends Vue {
	@Prop({ default: null, required: true }) private budget;
	@Prop({ default: null, required: true }) private days;
	@Prop({ default: null, required: true }) private people;
	@Prop({ default: null, required: true }) private students;
	@Prop({ default: null, required: false }) private errors;

	private activity_budget: any;
	private daysData: any;
	private activities: any[] = [];

	@Watch('people')
	people_change(value) {
		this.setDaysData();
		this.setPrice();
		if (this.people && this.budget.travel_id) {
			this.getSelectorList();
		}
	}

	@Watch('days')
	days_change(value) {
		this.setDaysData();
	}

	constructor() {
		super();
		this.activity_budget = new Budget();
		this.daysData = 0;
	}

	mounted() {
		if (this.budget) {
			this.activity_budget = Budget.copy(this.budget);
		}
		if (this.days) {
			this.setDaysData();
		}
		if (this.people && this.students) {
			this.setPrice();
		}
		if (this.people && this.budget.travel_id) {
			this.getSelectorList();
		}
	}

	getSelectorList() {
		ActivityService.getSelectorList(null, { travel_id: this.budget.travel_id, people: this.people })
			.then(res => {
				if (res.status === 200) {
					this.activities = res.data.elements;
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
	}

	setDaysData() {
		this.daysData = [];
		for (let i = 0; i < this.days; i++) {
			let existDay = null;
			for (const activity of this.activity_budget.activities) {
				if (activity.day == i) {
					existDay = activity;
				}
			}
			if (existDay !== null) {
				this.daysData.push(this.copy(existDay));
			} else {
				this.daysData.push({});
			}
		}
		// this.setPrice();
	}

	setPrice() {
		let total = 0;
		for (const day of this.daysData) {
			if (day.day_part_1 && day.day_part_1.activity_id) {
				total += this.getPricesAndExtras(day.day_part_1);
			}
			if (day.day_part_2 && day.day_part_2.activity_id) {
				total += this.getPricesAndExtras(day.day_part_2);
			}
			if (day.day_part_3 && day.day_part_3.activity_id) {
				total += this.getPricesAndExtras(day.day_part_3);
			}
		}
		this.$emit('setPrice', total);
	}

	getPricesAndExtras(day) {
		let total = 0;
		for (const price of day.prices_to_display) {
			if (price.group) {
				total += Math.round((price.price / this.students) * 100) / 100;
			} else {
				total += price.price;
			}
		}
		for (const extra of day.extras) {
			if (extra.group) {
				total += Math.round((extra.price / this.students) * 100) / 100;
			} else {
				total += extra.price;
			}
		}
		return total;
	}

	copy(model) {
		return {
			daypart: model.daypart,
			day_part_1: {
				day: model.day_part_1.day,
				people: this.people,
				daypart: 1,
				activity_id: model.day_part_1.activity_id,
				budget_activity_id: model.day_part_1.budget_activity_id,
				prices_to_display: model.day_part_1.prices_to_display,
				prices: model.day_part_1.prices,
				extras: model.day_part_1.extras,
			},
			day_part_2: {
				day: model.day_part_2.day,
				people: this.people,
				daypart: 2,
				activity_id: model.day_part_2.activity_id,
				budget_activity_id: model.day_part_2.budget_activity_id,
				prices_to_display: model.day_part_2.prices_to_display,
				prices: model.day_part_2.prices,
				extras: model.day_part_2.extras,
			},
			day_part_3: {
				day: model.day_part_3.day,
				people: this.people,
				daypart: 3,
				activity_id: model.day_part_3.activity_id,
				budget_activity_id: model.day_part_3.budget_activity_id,
				prices_to_display: model.day_part_3.prices_to_display,
				prices: model.day_part_3.prices,
				extras: model.day_part_3.extras,
			},
		};
	}

	async validate() {
		this.$emit('input', this.daysData);
		return true
	}
}
