import i18n from "./i18n";
import { extend } from "vee-validate";
import {required, email, min_value, image, max} from "vee-validate/dist/rules";

extend("required", {
	...required,
	message: (fieldName, placeholders) => {
		return `${i18n.t("validations.required", { _field_: i18n.t(fieldName) })}`;
	},
});

extend("email", {
	...email,
	message: (fieldName, placeholders) => {
		return `${i18n.t("validations.email", { _field_: i18n.t(fieldName) })}`;
	},
});

extend("min_value", {
	...min_value,
	message: (fieldName, placeholders) => {
		return `${i18n.t("validations.min_value", { _field_: i18n.t(fieldName), min: placeholders!.min })}`;
	},
});

extend("max", {
	...max,
	message: (fieldName, placeholders) => {
		return `${i18n.t("validations.max", { _field_: i18n.t(fieldName), length: placeholders!.length })}`;
	},
});

extend("image", {
	...image,
	message: (fieldName, placeholders) => {
		return `${i18n.t("validations.image", { _field_: i18n.t(fieldName) })}`;
	},
});
