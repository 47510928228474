export default class City {
  id: any;
  name: string;
  slug: string;
  state_id: any;


  constructor() {
    this.id = null;
    this.name = '';
    this.slug = '';
    this.state_id = null;
  }

  public static copy(model: any) {
    const aux = new City();
    aux.id = model.id;
    aux.name = model.name;
    aux.slug = model.slug;
    aux.state_id = model.state_id;
    return aux;
  }
}
