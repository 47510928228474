
	import { Component, Prop, Vue } from "vue-property-decorator";
	import TagService from "@/services/TagService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class Tags extends Vue {
		@Prop({ default: null, required: false }) private tag_ids;
		@Prop({ default: null, required: false }) private errors;

		private tags: any[] = [];
		private selectedTags: any[] = [];

		mounted() {
			TagService.selectorList()
				.then((res) => {
					if (res.status === 200) {
						this.tags = res.data.elements;
						this.selectedTags = this.tag_ids;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
