export default class TransportType {
	id: any;
	name: string;
	img_url: any;


	constructor() {
		this.id = null;
		this.name = '';
		this.img_url = null;
	}

	public static copy(model: any) {
		const aux = new TransportType();
		aux.id = model.id;
		aux.name = model.name;
		if (model.img_url) {
			aux.img_url = model.img_url;
		} else {
			aux.img_url = null;
		}
		return aux;
	}
}
