import { render, staticRenderFns } from "./ExtraServiceSubtypeSelect.vue?vue&type=template&id=5385be71&scoped=true&"
import script from "./ExtraServiceSubtypeSelect.vue?vue&type=script&lang=ts&"
export * from "./ExtraServiceSubtypeSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5385be71",
  null
  
)

export default component.exports