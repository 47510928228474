
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import AccomodationService from "@/services/AccomodationService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class AccommodationSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: true }) private errors;
		@Prop({ default: null, required: false }) private valid;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: true }) private people;

		private items: any[] = [];
		private total = 0;
		private selected = {};
		private value: {
			id: any;
			prices: any[];
			extras: any[];
			extra_ids: any[];
			increase_half_board: number;
			increase_full_board: number;
		} = {
			id: null,
			prices: [],
			extras: [],
			extra_ids: [],
			increase_half_board: 0,
			increase_full_board: 0
		};
		private filters: any;

		@Watch("model")
		model_change(value) {
			this.value = value;
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.filters.travel_id = value;
			if (this.filters.travel_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		@Watch("people")
		people_change(value) {
			this.filters.people = value;
			if (this.filters.travel_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		constructor() {
			super();
			this.filters = { travel_id: null, people: null };
		}

		mounted() {
			if (this.model) {
				this.value = this.model;
			}
			if (this.travel_id) {
				this.filters.travel_id = this.travel_id;
			}
			if (this.people != null) {
				this.filters.people = this.people;
			}
			if (this.filters.travel_id && this.filters.people != null) {
				this.getSelectorList();
			}
		}

		getSelectorList() {
			AccomodationService.getSelectorList(null, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data.elements;
						for (const accommodation of this.items) {
							if (this.value.id == accommodation.id) {
								this.setSelected(false);
								this.$emit('increases', {half_board: accommodation['increase_half_board'] ?? 0, full_board: accommodation['increase_full_board'] ?? 0 })
								break;
							}
						}
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		emitValues(){
			this.$emit('input', this.value);
			this.$emit('increases', {
				half_board: this.value['increase_half_board'],
				full_board: this.value['increase_full_board'],
			});
			this.$emit('setPrice', this.value);
		}

		setSelected(clear_extras = true) {
			this.selected = {};
			if (clear_extras) {
				this.value.extras = [];
				this.value.extra_ids = [];
			}
			for (const accommodation of this.items) {
				if (this.value.id == accommodation.id) {
					this.selected = accommodation;
					this.value.prices = accommodation.extras.prices;
					this.value.increase_half_board = accommodation.increase_half_board;
					this.value.increase_full_board = accommodation.increase_full_board;
					this.totalPrice()
					break;
				}
			}
			this.emitValues();
		}

		totalPrice(){
			let total = 0;
			if(this.value.id && this.value.prices){
				for (const price of this.value.prices) {
					total += price['price'];
				}
			}
			this.total = total;
		}

		setExtras() {
			this.value.extras = [];
			for (const extra of this.selected['extras']['extras']) {
				if (this.value.extra_ids.indexOf(extra['id'])  > -1) {
					this.value.extras.push(extra);
				}
			}
			this.emitValues();
		}
	}
