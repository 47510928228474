
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import FormatHelper from '@/helpers/format-helper';
import BudgetService from '@/services/BudgetService';
import ApiHelper from '@/helpers/api-helper';
import TabBudgetDestination from '@/components/inputs/TabBudgetDestination.vue';
import TabBudgetAccommodation from '@/components/inputs/TabBudgetAccommodation.vue';
import moment from 'moment';
import TabBudgetTransport from '@/components/inputs/TabBudgetTransport.vue';
import TabBudgetExtraServices from '@/components/inputs/TabBudgetExtraServices.vue';
import TabBudgetActivities from '@/components/inputs/TabBudgetActivities.vue';
import Budget from '@/models/budget';

@Component({
	components: {
		TabBudgetActivities,
		TabBudgetExtraServices,
		TabBudgetTransport,
		TabBudgetAccommodation,
		TabBudgetDestination,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class CustomerBudgetForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
		tabBudgetDestination: InstanceType<typeof TabBudgetDestination>;
		tabBudgetAccommodation: InstanceType<typeof TabBudgetAccommodation>;
		tabBudgetTransport: InstanceType<typeof TabBudgetTransport>;
		tabBudgetExtraServices: InstanceType<typeof TabBudgetExtraServices>;
		tabBudgetActivities: InstanceType<typeof TabBudgetActivities>;
	}

	private loading = true;
	private activeTab = 0;
	private total: any;
	private budget: Budget = new Budget();
	private people: any;
	private students: any;
	private days: any;

	constructor() {
		super();
		this.people = 0;
		this.students = 0;
		this.days = 0;
		this.total = {
			accommodation_price: 0,
			transport_price: 0,
			extra_service_price: 0,
			activity_price: 0,
		};
	}

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			BudgetService.get(this.edit_id)
				.then(res => {
					if (res.status === 200) {
						this.budget = Budget.copy(res.data);
						this.people = this.budget.students + this.budget.teachers;
						this.students = this.budget.students;
						const days = moment(this.budget.end_date).diff(moment(this.budget.start_date), 'days') + 1;
						this.days = days;
						this.total.accommodation_price = this.budget.accommodation.price * (days -1);
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	async update() {
		const component = this;
		const valid = await this.validateAllTab();
		if (valid) {
			BudgetService.update(this.budget)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	async validateAllTab() {
		let valid = false;
		for (let i = 0; i <= 4; i++) {
			this.activeTab = i;
			valid = await this.validateTab(i);
			if (!valid) {
				break;
			}
		}
		return valid;
	}

	get isDisabledTab() {
		return false;
	}

	async validateTab(tab) {
		let valid = false;
		if (tab == 0) {
			valid = await this.$refs['tabBudgetDestination'].validate();
		}
		if (tab == 1) {
			valid = await this.$refs['tabBudgetAccommodation'].validate();
		}
		if (tab == 2) {
			valid = await this.$refs['tabBudgetTransport'].validate();
		}
		if (tab == 3) {
			valid = await this.$refs['tabBudgetExtraServices'].validate();
		}
		if (tab == 4) {
			valid = await this.$refs['tabBudgetActivities'].validate();
		}
		if (!valid) {
			this.activeTab = tab;
		}
		return valid;
	}

	setBudget(data) {
		this.budget.start_date = data.start_date;
		this.budget.end_date = data.end_date;
		this.budget.students = data.students;
		this.budget.teachers = data.teachers;
		this.budget.who = data.who;
		this.budget.center = data.center;
		this.budget.customer_state_id = data.customer_state_id;
		this.budget.ages_id = data.ages_id;
		this.budget.responsible_name_surname = data.responsible_name_surname;
		this.budget.responsible_email = data.responsible_email;
		this.budget.responsible_phone = data.responsible_phone;
		this.budget.others = data.others;
		this.people = parseInt(this.budget.students) + parseInt(this.budget.teachers);
		const days = moment(this.budget.end_date).diff(moment(this.budget.start_date), 'days') + 1;
		this.days = days;
	}

	setPrice(value, type) {
		if (type == 'accommodation_price') {
			this.total[type] = value * (this.days - 1);
		}  else{
			this.total[type] = value;
		}
	}

	get sumTotal() {
		let sum = 0;
		for (const el in this.total) {
			if (Object.prototype.hasOwnProperty.call(this.total, el)) {
				sum += parseFloat(this.total[el]);
			}
		}
		// Suplemento de profesores

		if(this.budget.teachers > (this.budget.students / this.budget.free_teacher_by_students)) {
			const amount_with_supplement = ((this.budget.supplement * sum) / 100) + sum;
			const teacher_amount = (amount_with_supplement / this.budget.students) *
				(this.budget.teachers - ~~(this.budget.students / this.budget.free_teacher_by_students));
			sum += teacher_amount;
		}
		return FormatHelper.formatPrice(sum);
	}
}
