<template>
	<v-breadcrumbs dark :items="crumbs" />
</template>

<script>
	export default {
		computed: {
			crumbs: function() {
				const pathArray = this.$route.path.split("/")
				pathArray.shift()
				const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
				breadcrumbArray.push({
					path: path,
					name: name,
					to: breadcrumbArray[idx - 1]
					? "/" + breadcrumbArray[idx - 1].path + "/" + path: "/" + path,
					text: this.$route.matched[idx].name || path,
				});
				return breadcrumbArray;
				}, [])
				return breadcrumbs;
			}
		}
	}
</script>

<style lang="scss">
	.v-breadcrumbs li:nth-child(-n+2) {
		display: none;
	}

	.v-breadcrumbs__item {
		text-transform: uppercase;
	}
</style>
