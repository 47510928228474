<template>
	<AccomodationsTable />
</template>

<script>
	import AccomodationsTable from "@/components/tables/AccomodationsTable.vue";

	export default {
		name: "Accomodations",
		components: {
			AccomodationsTable
		}
	};
</script>
