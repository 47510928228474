
	import { Component, Vue } from "vue-property-decorator";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import ImageUpload from "@/components/inputs/ImageUpload.vue";
	import ExtraServiceTypeSelect from "@/components/inputs/ExtraServiceTypeSelect.vue";
	import BulkTransportTypes from "@/models/bulk_transport_types";
	import BulkExtraServiceTypes from "@/models/bulk_extra_service_types";
	import TransportType from "@/models/transport_type";
	import ServiceType from "@/models/service_type";
	import TransportTypeService from "@/services/TransportTypeService";
	import ApiHelper from "@/helpers/api-helper";
	import ServicesTypeService from "@/services/ServicesTypeService";
	import i18n from '@/plugins/i18n';

	@Component({
		components: {
			ValidationProvider,
			ValidationObserver,
			ImageUpload,
			ExtraServiceTypeSelect,
		},
	})
	export default class TypesConfiguration extends Vue {
		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
		};

		private transports: any;
		private services: any;
		private pagination: any;
		private filters: any;

		constructor() {
			super();
			this.transports = {};
			this.transports.transport_types = [];
			this.services = {};
			this.services.extra_service_types = [];
			this.pagination = null;
			this.filters = {};
		}

		mounted() {
			this.listTransport();
			this.listService();
		}

		listTransport() {
			TransportTypeService.list(this.pagination, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.transports.transport_types = res.data.elements;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		bulkUpdateTransport() {
			const component = this;
			TransportTypeService.bulkUpdate(this.transports)
				.then((res) => {
					if (res.status === 200) {
						if(res.data.allSelectedDeleted) {
							this.$bus.$emit('success', {message: i18n.t('success_saved_message')});
						} else {
							this.$bus.$emit('error', { message: i18n.t('transport_types_with_transports')});
						}
						this.listTransport();
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, component.$refs["obs"], this.$bus);
				});
		}

		addTransportRow() {
			this.transports.transport_types.push(new TransportType());
			return false;
		}

		deleteTransport(transport) {
			const index = this.transports.transport_types.indexOf(transport);
			this.transports.transport_types.splice(index, 1);
		}

		listService() {
			ServicesTypeService.list(this.pagination, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.services.extra_service_types = res.data.elements;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		bulkUpdateService() {
			const component = this;
			ServicesTypeService.bulkUpdate(this.services)
				.then((res) => {
					if (res.status === 200) {
						if(res.data.allSelectedDeleted) {
							this.$bus.$emit('success', {message: i18n.t('success_saved_message')});
						} else {
							this.$bus.$emit('error', { message: i18n.t('service_types_with_prices')});
						}
						this.listService();
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, component.$refs["obs"], this.$bus);
				});
		}

		addServicesRow() {
			this.services.extra_service_types.push(new ServiceType());
			return false;
		}

		deleteService(service) {
			const index = this.services.extra_service_types.indexOf(service);
			this.services.extra_service_types.splice(index, 1);
		}

		async validateFile($event, index) {
			await this.$refs["img_url"][index].validate($event);
		}

		isImageUploaded(img) {
			return typeof img === "string";
		}
	}
