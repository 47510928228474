
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ApiHelper from '@/helpers/api-helper';
import BudgetService from '@/services/BudgetService';
import AccomodationService from '@/services/AccomodationService';
import FormatHelper from '@/helpers/format-helper';

@Component({
	components: {
		ValidationProvider,
		ValidationObserver,
	},
})
export default class CustomerBudgetPdfForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	private loading = false;
	private formTitle = '';
	private budget = {};

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			this.formTitle = 'Edit budget PDF';
			BudgetService.getPdfDetails(this.edit_id)
				.then(res => {
					if (res.status === 200) {
						this.budget = res.data;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			this.formTitle = 'New budget';
		}
	}

	async update() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		if (validForm) {
			BudgetService.updatePDF(this.budget)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	getAcommodationType(feature_id) {
		if (feature_id == AccomodationService.BOARD_BASIC_TYPE_ID) {
			return 'Desayuno incluido';
		} else if (feature_id == AccomodationService.BOARD_HALF_TYPE_ID) {
			return  'Media pensión (Desayuno y cena)';
		} else if (feature_id == AccomodationService.BOARD_FULL_TYPE_ID) {
			return 'Pensión completa (Desayuno, almuerzo y cena)'
		}
	}

	priceFormat(price) {
		return FormatHelper.formatPrice(price);
	}
}
