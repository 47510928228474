<template>
	<ProvidersTable />
</template>

<script>
	import ProvidersTable from "@/components/tables/ProvidersTable.vue";

	export default {
		name: "Providers",
		components: {
			ProvidersTable
		}
	};
</script>
