import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Travel from "@/models/travel";

export default class TravelService {
	private static fields = [
		'name',
		'description',
		'relevance',
		'city_id',
		'lat',
		'lon',
		'season_start',
		'season_end',
		'duration_min',
		'duration_max',
		'free_teacher_by_students',
		'supplement',
		'status_id',
		'pdf_cover_img',
		'pdf_back_cover_img',
		'tag_ids'
	]

	public static get(id) {
		return axios.get(`/travel/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/travel", { params: params });
	}

	public static getSelectorList() {
		return axios.get(`/travel/filter`);
	}

	public static create(travel: Travel) {
		const data = ApiHelper.buildParamsWithFile(this.fields, travel);
		return axios.post('travel', data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static update(travel: Travel) {
		const data = ApiHelper.buildParamsWithFile(this.fields, travel);
		return axios.post(`/travel/${travel.id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static changeStatus(travel: Travel) {
		return axios.get(`/travel/changeStatus/${travel.id}/${travel.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/travel/${id}`);
	}
}
