
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TravelSelect from '@/components/inputs/TravelSelect.vue';
import ImageUpload from '@/components/inputs/ImageUpload.vue';
import Package from '@/models/package';
import DatePicker from '@/components/inputs/DatePicker.vue';
import moment from 'moment';
import TextEditor from '@/components/inputs/TextEditor.vue';

@Component({
	components: {
		TextEditor,
		DatePicker,
		ImageUpload,
		TravelSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class TabTravelPackage extends Vue {
	@Prop({ default: null, required: true }) private package;
	@Prop({ default: null, required: false }) private errors;

	private packageData: any;
	private offer_min_date: any;
	private offer_end_date_max_date: any;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
		img_url;
	};

	// @Watch("package")
	// package_change(value) {
	// 	this.copyPackage(value);
	// }

	constructor() {
		super();
		this.packageData = new Package();
		this.offer_min_date = moment()
			.add(2, 'days')
			.format('YYYY-MM-DD');
		this.offer_end_date_max_date = '';
	}

	mounted() {
		if (this.package) {
			this.copyPackage(this.package);
		}
	}

	copyPackage(data) {
		this.packageData = Package.copy(data);
		if (this.packageData.offer) {
			this.setOfferEndDateMax();
		}
	}

	get isImageUploaded() {
		return typeof this.package.img_url === 'string';
	}

	async validateFile($event) {
		await this.$refs['img_url'].validate($event);
	}

	async validate() {
		this.$emit('input', this.packageData);
		return await this.$refs.obs.validate();
	}

	emitValue() {
		// Esto es porque al cambiar oferta, debe mostrar u ocultrar el precio en el tab
		this.$emit('input', this.packageData);
	}

	setOfferEndDateMax() {
		this.offer_end_date_max_date = moment(this.packageData.offer_start_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
	}
}
