import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import BulkExtraServiceTypes from "@/models/bulk_extra_service_types";

export default class ServicesTypeService {

	public static get(id) {
		return axios.get(`/extra_service_type/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/extra_service_type", { params: params });
	}

	public static getSelectorList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/extra_service_type/filter`,{ params: params });
	}

	public static bulkUpdate(serviceType: any) {
		const formData = new FormData();
		formData.append('extra_service_types', JSON.stringify(serviceType.extra_service_types));
		for (const index in serviceType.extra_service_types) {
			if(serviceType.extra_service_types[index].img_url && typeof serviceType.extra_service_types[index].img_url.lastModified  === 'number')
				formData.append('img_url_' + index, serviceType.extra_service_types[index].img_url);
		}
		return axios.post(`/extra_service_type/bulk/update`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static delete(id) {
		return axios.delete(`/extra_service_type/${id}`);
	}
}
