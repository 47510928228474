
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { ValidationProvider, ValidationObserver } from 'vee-validate';
	import ApiHelper from '@/helpers/api-helper';
	import PackageService from '@/services/PackageService';
	import TravelSelect from '@/components/inputs/TravelSelect.vue';
	import TabTravelPackage from '@/components/inputs/TabTravelPackage.vue';
	import TabAccommodationPackage from '@/components/inputs/TabAccommodationPackage.vue';
	import TabTransportPackage from '@/components/inputs/TabTransportPackage.vue';
	import TabExtraServicePackage from '@/components/inputs/TabExtraServicePackage.vue';
	import TabActivityPackage from '@/components/inputs/TabActivityPackage.vue';
	import Package from '@/models/package';
	import FormatHelper from '@/helpers/format-helper';

	@Component({
		components: {
			TabTravelPackage,
			TabAccommodationPackage,
			TabTransportPackage,
			TabExtraServicePackage,
			TabActivityPackage,
			TravelSelect,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TravelForm extends Vue {
		@Prop({ default: null, required: true }) private edit_id;

		$refs!: {
			obs: InstanceType<typeof ValidationObserver>;
			tabTravelPackage: InstanceType<typeof TabTravelPackage>;
			tabAccommodationPackage: InstanceType<typeof TabAccommodationPackage>;
			tabTransportPackage: InstanceType<typeof TabTransportPackage>;
			tabExtraServicePackage: InstanceType<typeof TabExtraServicePackage>;
			tabActivityPackage: InstanceType<typeof TabActivityPackage>;
		};

		private loading = true;
		private formTitle = '';
		private package_id: any = null;
		private package: Package = new Package();
		private activeTab = 0;
		private travel_id: any;
		private total: any;
		private people: any;

		constructor() {
			super();
			this.travel_id = null;
			this.people = 0;
			this.total = {
				accommodation_price: 0,
				transport_price: 0,
				extra_service_price: 0,
				activity_price: 0,
			};
		}

		mounted() {
			this.package_id = this.edit_id;
			if (this.package_id) {
				this.loading = true;
				this.formTitle = 'Edit Package';
				PackageService.get(this.package_id)
					.then(res => {
						if (res.status === 200) {
							this.package = res.data;
							this.travel_id = this.package['travel_id'];
							this.people = this.package['people'];
							this.loading = false;
						}
					})
					.catch(error => {
						ApiHelper.handleErrors(error, {}, this.$bus);
					});
			} else {
				this.formTitle = 'New Package';
				this.loading = false;
			}
		}

		async create() {
			const component = this;
			const valid = await this.validateAllTab();
			if (valid) {
				PackageService.create(this.package)
					.then(res => {
						if (res.status === 201) {
							this.$emit('saved');
						}
					})
					.catch(error => {
						ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
					});
			}
		}

		async update() {
			const component = this;
			const valid = await this.validateAllTab();
			if (valid) {
				PackageService.update(this.package)
					.then(res => {
						if (res.status === 200) {
							this.$emit('saved');
						}
					})
					.catch(error => {
						ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
					});
			}
		}

		async validateAllTab() {
			let valid = false;
			for (let i = 0; i <= 4; i++) {
				this.activeTab = i;
				valid = await this.validateTab(i);
				if (!valid) {
					break;
				}
			}
			return valid;
		}

		async validateTab(tab) {
			let valid = false;
			if (tab == 0) {
				valid = await this.$refs['tabTravelPackage'].validate();
			}
			if (tab == 1) {
				valid = await this.$refs['tabAccommodationPackage'].validate();
			}
			if (tab == 2) {
				valid = await this.$refs['tabTransportPackage'].validate();
			}
			if (tab == 3) {
				valid = await this.$refs['tabExtraServicePackage'].validate();
			}
			if (tab == 4) {
				valid = await this.$refs['tabActivityPackage'].validate();
			}
			if (!valid) {
				this.activeTab = tab;
			}
			return valid;
		}

		get isDisabledTab() {
			return !this.travel_id;
		}

		setTravelId(travel_id) {
			this.travel_id = travel_id;
		}

		setPrice(value, type) {
			if (type == 'accommodation_price') {
				this.total[type] = value * (this.package.days - 1);
			} else {
				this.total[type] = value;
			}
		}

		get sumTodal() {
			let sum = 0;
			for (const el in this.total) {
				if (Object.prototype.hasOwnProperty.call(this.total, el)) {
					sum += parseFloat(this.total[el]);
				}
			}
			return FormatHelper.formatPrice(sum);
		}

		setTravelPackage(packageData) {
			this.package.name = packageData.name;
			this.package.description = packageData.description;
			this.package.img_url = packageData.img_url;
			this.package.status_id = packageData.status_id;
			this.package.travel_id = packageData.travel_id;
			this.package.days = packageData.days;
			this.package.offer = packageData.offer;
			this.package.offer_start_date = packageData.offer_start_date;
			this.package.offer_end_date = packageData.offer_end_date;
			this.package.offer_amount = packageData.offer_amount;
			this.package.people = packageData.people;
			this.people = packageData.people;
		}
	}
