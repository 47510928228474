<template>
	<TransportTable />
</template>

<script>
	import TransportTable from "@/components/tables/TransportTable.vue";

	export default {
		name: "Transport",
		components: {
			TransportTable
		}
	};
</script>
