
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TransportService from '@/services/TransportService';
import Transport from '@/models/transport';
import ApiHelper from '@/helpers/api-helper';
import TransportTypeSelect from '@/components/inputs/TransportTypeSelect.vue';
import TransportPrices from '@/components/inputs/TransportPrices.vue';
import TextEditor from '@/components/inputs/TextEditor.vue';
import ProviderSelect from '@/components/inputs/ProviderSelect.vue';
import ProvinceSelect from '@/components/inputs/ProvinceSelect.vue';
import CountrySelect from '@/components/inputs/CountrySelect.vue';
import TravelSelect from '@/components/inputs/TravelSelect.vue';

@Component({
	components: {
		TravelSelect,
		CountrySelect,
		ProvinceSelect,
		ProviderSelect,
		TextEditor,
		TransportPrices,
		TransportTypeSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class TransportForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
		transportPrices: InstanceType<typeof TransportPrices>;
	};

	private loading = false;
	private formTitle = '';
	private transport: Transport = new Transport();

	mounted() {
		if (this.edit_id) {
			this.loading = true;
			this.formTitle = 'Edit transport';
			TransportService.get(this.edit_id)
				.then(res => {
					if (res.status === 200) {
						this.transport = res.data;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			this.formTitle = 'New transport';
		}
	}

	async create() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['transportPrices'].validate();
		if (validForm && validPrices) {
			TransportService.create(this.transport)
				.then(res => {
					if (res.status === 201) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}

	async update() {
		const component = this;
		const validForm = await this.$refs['obs'].validate();
		const validPrices = await this.$refs['transportPrices'].validate();
		if (validForm && validPrices) {
			TransportService.update(this.transport)
				.then(res => {
					if (res.status === 200) {
						this.$emit('saved');
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
				});
		}
	}
}
