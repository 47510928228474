
import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionConfirmation from '@/components/modals/ActionConfirmation.vue';
import ProviderForm from '@/components/forms/ProviderForm.vue';
import i18n from '@/plugins/i18n';
import Provider from '@/models/provider';
import ProviderService from '@/services/ProviderService';
import ApiHelper from '@/helpers/api-helper';
import FormatHelper from '@/helpers/format-helper';

@Component({
	components: {
		ActionConfirmation,
		ProviderForm,
	},
})
export default class ProvidersTable extends Vue {
	@Watch('pagination')
	onPaginationChanged() {
		this.getList();
	}

	@Watch('filters.search')
	onSearchChanged(value: string) {
		if (!value || value.length === 0) {
			this.getList();
		} else if (value.length >= 3) {
			this.getList();
		}
	}

	$refs!: {
		actionConfirmation: InstanceType<typeof ActionConfirmation>;
	};

	private pagination: any;
	private filters: any;
	private lastFilters: any;
	private loading: boolean;
	private drawer: boolean;
	private edit_id: any;
	private providers: Provider[];
	private total: number;
	private aux_id: any;

	constructor() {
		super();
		this.pagination = {};
		this.filters = { country_id: null };
		this.lastFilters = {};
		this.loading = false;
		this.drawer = false;
		this.edit_id = null;
		this.providers = [];
		this.total = 0;
		this.aux_id = null;
	}

	private headers = [
		{
			text: i18n.t('business_name'),
			value: 'business_name',
			align: 'start',
			sortable: true,
		},
		{
			text: i18n.t('creation_date'),
			value: 'created_at',
			align: 'start',
			sortable: true,
		},
		{
			text: i18n.t('actions'),
			value: 'actions',
			align: 'end',
			sortable: false,
		},
	];

	getList(force = false) {
		if (ApiHelper.filterHasChanged(this.pagination, this.filters, this.lastFilters) || force) {
			this.loading = true;
			ProviderService.list(this.pagination, this.filters)
				.then(res => {
					if (res.status === 200) {
						this.providers = res.data.elements;
						this.total = res.data.total;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}

	onEdit(id) {
		this.edit_id = id;
		this.drawer = true;
		document.body.classList.add('drawer-open');
	}

	onNew() {
		this.edit_id = null;
		this.drawer = true;
		document.body.classList.add('drawer-open');
	}

	closeDrawer() {
		this.drawer = false;
		document.body.classList.remove('drawer-open');
	}

	onDelete(id) {
		this.aux_id = id;
		this.$refs['actionConfirmation'].show();
	}

	confirmDelete() {
		ProviderService.delete(this.aux_id)
			.then(res => {
				if (res.status === 204) {
					this.aux_id = null;
					this.getList(true);
					this.$refs['actionConfirmation'].close();
				}
			})
			.catch(error => {
				if (error.response && error.response.status == 503) {
					this.$bus.$emit('error', { error: error, message: error.response.data });
				} else {
					ApiHelper.handleErrors(error, {}, this.$bus);
				}
			});
	}

	dateFormat(date) {
		return FormatHelper.formatDate(date);
	}
}
