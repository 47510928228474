
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TravelSelect from '@/components/inputs/TravelSelect.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import Budget from '@/models/budget';
import moment from 'moment';
import WhoSelect from '@/components/inputs/WhoSelect.vue';
import ProvinceSelect from '@/components/inputs/ProvinceSelect.vue';
import GroupAgeSelect from '@/components/inputs/GroupAgeSelect.vue';

@Component({
	components: {
		GroupAgeSelect,
		ProvinceSelect,
		WhoSelect,
		DatePicker,
		TravelSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class TabBudgetDestination extends Vue {
	@Prop({ default: null, required: true }) private budget;
	@Prop({ default: null, required: false }) private errors;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	private destination_budget: any;
	private minDateStartModel: any;
	private maxDateStartModel: any;
	private minDateFinishModel: any;
	private maxDateFinishModel: any;

	constructor() {
		super();
		this.destination_budget = new Budget();
		this.minDateStartModel = '';
		this.maxDateStartModel = '';
		this.minDateFinishModel = '';
		this.maxDateFinishModel = '';
	}

	mounted() {
		if (this.budget) {
			this.destination_budget = Budget.copy(this.budget);
			this.minDateStartModel =  moment().format('YYYY-MM-DD');
			this.maxDateStartModel = moment(this.budget.travel_season_end, 'YYYY-MM-DD').format('YYYY-MM-DD');
			this.setEndDateLimits();
		}
	}



	setEndDateLimits() {

		this.minDateFinishModel = moment(this.destination_budget.start_date, 'YYYY-MM-DD').add(this.budget.travel_duration_min, 'days').format('YYYY-MM-DD');
		this.maxDateFinishModel = moment(this.destination_budget.start_date, 'YYYY-MM-DD').add(this.budget.travel_duration_max - 1, 'days').format('YYYY-MM-DD');

		if (this.minDateFinishModel > this.destination_budget.end_date) {
			this.destination_budget.end_date = null;
		}
	}

	async validate() {
		this.$emit('input', this.destination_budget);
		return await this.$refs.obs.validate();
	}

	emitValue() {
		// Esto es para actualizar el precio con el suplemento de profesores
		this.$emit('input', this.destination_budget);
	}
}
