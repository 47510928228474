<template>
	<v-app id="login" light>
		<v-container fill-height fluid>
			<v-layout align-center justify-center>
				<v-flex xs12 sm8 md4>

					<transition mode="out-in">
						<LoginForm/>
					</transition>

				</v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>

<script>
	import LoginForm from '@/components/LoginForm'

	export default {
		name: 'Login',
		components: {
			LoginForm
		}
	}
</script>

<style>
	#login {
		background-image: url('../assets/img/bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
	}
</style>
