
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import ApiHelper from "@/helpers/api-helper";
	import ServicesTypeService from "../../services/ServicesTypeService";

	@Component({})
	export default class ExtraServiceSubtypeSelect extends Vue {
		@Prop({ default: null, required: false }) private extra_service_type_id;
		@Prop({ default: null, required: false }) private type_id;
		@Prop({ default: null, required: false }) private errors;

		@Watch("type_id")
		type_id_change(value: string, oldValue: string) {
			this.type_id = value;
			if (this.type_id) {
				this.filters.extra_service_type_id = this.type_id;
				this.getSelectorList();
			}
		}

		private items: any[] = [];
		private pagination: any = null;
		private filters: any = {};
		private value = null;

		mounted() {
			if (this.type_id) {
				this.filters.extra_service_type_id = this.type_id;
				this.getSelectorList();
			}
		}

		getSelectorList() {
			ServicesTypeService.getSelectorList(this.pagination, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data;
						this.value = this.extra_service_type_id;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
