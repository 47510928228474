import axios from 'axios'
import Province from "@/models/province";
import ApiHelper from "@/helpers/api-helper";

export default class CountryService {
	private static fields = [
		'name',
		'country_id',
		'slug'
	]

	public static getSelectorList(id = null) {
		let url = '/v1/state/filter';
		if (id) {
			url += '?country_id=' + id;
		}
		return axios.get(url);
	}

	public static create(province: Province) {
		const data = ApiHelper.buildParams(this.fields, province);
		return axios.post(`/v1/state`, data);
	}
}
