
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";
	import ProvinceService from "@/services/ProvinceService";
	import ApiHelper from "@/helpers/api-helper";
	import Province from "@/models/province";

	@Component({})
	export default class ProvinceSelect extends Vue {
		@Prop({ default: 64, required: false }) private country_id;
		@Prop({ default: null, required: false }) private id_prov;
		@Prop({ default: null, required: false }) private errors;
		@Prop({ default: null, required: false }) private except;
		@Prop({ default: true, required: false }) private with_creation;
		@Prop({ default: null, required: false }) private placeholder;

		@Watch("country_id")
		country_id_change(value: string, oldValue: string) {
			this.id_country = value;
			this.getSelectorList();
		}

		private items: any[] = [];
		private id_country: any = null;
		private value = null;
		private search = null;
		private slug = "";
		private province: Province = new Province();

		mounted() {
			this.id_country = this.country_id;
			this.getSelectorList();
		}

		getSelectorList() {
			if (this.id_country) {
				ProvinceService.getSelectorList(this.id_country)
					.then((res) => {
						if (res.status === 200) {
							this.prepareOptions(res.data);
							if (this.id_prov.id) {
								this.value = this.id_prov;
							}
						}
					})
					.catch((error) => {
						ApiHelper.handleErrors(error, {}, this.$bus);
					});
			}
		}

		prepareOptions(data) {
			this.items = [];
			for (const element of data) {
				if (!this.except || this.except != element['id']) {
					this.items.push(element);
				}
			}
		}

		create(name) {
			this.string_to_slug(name);
			if (this.id_country) {
				const component = this;
				this.province = {
					id: null,
					name: name,
					country_id: this.id_country,
					slug: this.slug,
				};
				ProvinceService.create(this.province)
					.then((res) => {
						if (res.status === 201) {
							this.getSelectorList();
						}
					})
					.catch((error) => {
						ApiHelper.handleErrors(error, component.$refs["obs"], this.$bus);
					});
			}
		}

		string_to_slug(str) {
			str = str.replace(/^\s+|\s+$/g, ""); // trim
			str = str.toLowerCase();

			// remove accents, swap ñ for n, etc
			const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			const to = "aaaaeeeeiiiioooouuuunc------";
			for (let i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
			}

			str = str
				.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
				.replace(/\s+/g, "-") // collapse whitespace and replace by -
				.replace(/-+/g, "-"); // collapse dashes

			this.slug = str;
		}
	}
