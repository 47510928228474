
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AccomodationService from "@/services/AccomodationService";
import ApiHelper from "@/helpers/api-helper";

@Component({})
export default class FeatureAccommodationSelect extends Vue {
	@Prop({ default: null, required: false }) private model;
	@Prop({ default: null, required: true }) private errors;
	@Prop({ default: false, required: false }) private show_boards;
	@Prop({ default: null, required: false }) private increases;

	private items: any[] = [];
	private value = null;
	private filters: any;
	private allItems: any[] = [];
	private values: any;

	@Watch("model")
	model_change(value) {
		this.value = value;
	}

	@Watch("increases")
	increases_change(value) {
		this.values = value;
		//o
		this.getSelectorList();
	}


	mounted() {
		if (this.model) {
			this.value = this.model;
		}
		if (this.show_boards) {
			this.filters = { type: 1 };
		}
			this.getSelectorList();
		//this.items = this.allItems;
	}

	getSelectorList() {
		if (this.show_boards) {
			AccomodationService.getSelectorFeatureList(null, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.prepareItems(res.data)
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			AccomodationService.getSelectorTypeList()
				.then((res) => {
					if (res.status === 200) {
					this.items = res.data;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
	prepareItems(itemsData){
		this.items = [];
		if (this.values.full_board == null)
		{
			itemsData.splice(2,1);
		}
		if (this.values.half_board == null)
		{
			itemsData.splice(1,1);
		}
		this.items = itemsData;
	}
}
