import axios from 'axios'
import Store from '@/store/index'

export default class UserService {

	public static me() {
		return axios.get('/v1/user/me');
	}

	public static login(email: string, password: string) {
		const data = {
			email : email,
			password: password
		};
		return axios.post('/login-admin', data);
	}

	public static checkProfile(profile: string) {
		const user = Store.getters.loggedUser;
		for (const access of user['access']){
			if(access.profile == profile){
				return true;
			}
		}
		return false;
	}
}
