
import {Component, Prop, Vue, Watch,} from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AccommodationSelect from "@/components/inputs/AccommodationSelect.vue";
import FeatureAccommodationSelect from "@/components/inputs/FeatureAccommodationSelect.vue";
import AccommodationPriceSelect from "@/components/inputs/AccommodationPriceSelect.vue";
import Budget from '../../models/budget';

@Component({
	components: {
		AccommodationSelect,
		FeatureAccommodationSelect,
		AccommodationPriceSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class TabBudgetAccommodation extends Vue {
	@Prop({ default: null, required: true }) private budget;
	@Prop({ default: null, required: true }) private people;
	@Prop({ default: null, required: false }) private errors;

	$refs!: {
		obs: InstanceType<typeof ValidationObserver>;
	};

	private accommodation_budget: any;
	private increases = {};

	constructor() {
		super();
		this.accommodation_budget = new Budget();
	}
	mounted() {
		if (this.budget) {
			this.accommodation_budget = Budget.copy(this.budget);
		}
	}

	setAccommodationIdPricesAndExtras(data) {
		this.accommodation_budget.accommodation.id = data.id;
		this.accommodation_budget.accommodation.prices = data.prices;
		this.accommodation_budget.accommodation.extras = data.extras;
	}

	async validate() {
		this.$emit('input', this.accommodation_budget);
		return await this.$refs.obs.validate();
	}

	setPrice(value){
		if (value) {
			this.accommodation_budget.accommodation.price = value.price;
		}
		let total = 0;
		for (const price of this.accommodation_budget.accommodation.prices) {
			total += price.price;
		}
		for (const extra of this.accommodation_budget.accommodation.extras) {
			total += extra.price;
		}
		total += this.accommodation_budget.accommodation.price;
		this.$emit('setPrice', total);
	}
}
