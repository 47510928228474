import axios from 'axios';
import City from "@/models/city";
import ApiHelper from "@/helpers/api-helper";

export default class CityService {
	private static fields = [
		'name',
		'state_id',
		'slug'
	]

	public static getSelectorList(id, filter = 'state_id') {
		return axios.get(`/v1/city/filter?${filter}=${id}`);
	}

	public static getSelectorWithTravelsList(id, filter = 'state_id') {
		return axios.get(`/travel/filter-city?${filter}=${id}`);
	}

	public static create(city: City) {
		const data = ApiHelper.buildParams(this.fields, city);
		return axios.post(`/v1/city`, data );
	}
}
