import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Transport from "@/models/transport";

export default class TransportService {
	private static fields = [
		'name',
		'description',
		'travel_id',
		'state_id',
		'status_id',
		'type_id',
		'prices',
		'provider_ids',
		'travel_ids',
	]

	public static get(id) {
		return axios.get(`/transport/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/transport", { params: params });
	}

	public static getSelectorList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/transport/filter`, { params: params });
	}

	public static getSelectorPriceList(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get(`/transport_price/filter`, { params: params });
	}

	public static create(transport: Transport) {
		const data = ApiHelper.buildParams(this.fields, transport);
		return axios.post('transport', data);
	}

	public static update(transport: Transport) {
		const data = ApiHelper.buildParams(this.fields, transport);
		return axios.put(`/transport/${transport.id}`, data);
	}

	public static changeStatus(transport: Transport) {
		return axios.get(`/transport/changeStatus/${transport.id}/${transport.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/transport/${id}`);
	}
}
