import Vue from 'vue';
import VueRouter from 'vue-router';

// views
import Login from '../views/Login.vue';

import Admin from '../views/admin/Admin.vue';
import Travels from '../views/admin/Travels.vue';
import Accomodations from '../views/admin/Accomodations.vue';
import Transport from '../views/admin/Transport.vue';
import Services from '../views/admin/Services.vue';
import Activities from '../views/admin/Activities.vue';
import Packages from '../views/admin/Packages.vue';
import Providers from '../views/admin/Providers.vue';
import CustomerBudgets from '../views/admin/CustomerBudgets.vue';

// comps
import TypesConfiguration from '@/components/forms/TypesConfiguration.vue';

import { authGuard } from '@/session/guards/authGuard';
import { userResolver } from '@/session/resolvers/userResolver';
import { langResolver } from '@/session/resolvers/langResolver';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
		meta: {
			title: 'Acceder',
		},
	},
	{
		path: '/admin',
		component: Admin,
		meta: {
			requiresAuth: true,
			userResolver: true,
		},
		children: [
			{
				path: 'viajes',
				name: 'Viajes',
				component: Travels,
				meta: {
					title: 'Viajes',
				},
			},
			{
				path: 'alojamientos',
				name: 'Alojamientos',
				component: Accomodations,
				meta: {
					title: 'Alojamientos',
				},
			},
			{
				path: 'transporte',
				name: 'Transporte',
				component: Transport,
				meta: {
					title: 'Transporte',
				},
			},
			{
				path: 'servicios-extra',
				name: 'Servicios extra',
				component: Services,
				meta: {
					title: 'Servicios extra',
				},
			},
			{
				path: 'actividades',
				name: 'Actividades',
				component: Activities,
				meta: {
					title: 'Actividades',
				},
			},
			{
				path: 'paquetes',
				name: 'Paquetes',
				component: Packages,
				meta: {
					title: 'Paquetes',
				},
			},
			{
				path: 'proveedores',
				name: 'Proveedores',
				component: Providers,
				meta: {
					title: 'Proveedores',
				},
			},
			{
				path: 'presupuestos',
				name: 'Presupuestos',
				component: CustomerBudgets,
				meta: {
					title: 'Presupuestos',
				},
			},
			{
				path: 'configuracion',
				name: 'Configuración general',
				component: TypesConfiguration,
				meta: {
					title: 'Configuración general',
				},
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeResolve(userResolver);
router.beforeEach(authGuard);
router.beforeEach(langResolver);

router.beforeEach((to, from, next) => {
	document.title = to.meta!.title + ' | Viajes Fin de Curso';
	document.body.classList.remove('drawer-open');
	next();
});

export default router;
