
	import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import ExtraServiceService from "@/services/ExtraService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class ExtraServiceSelect extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;
		@Prop({ default: null, required: false }) private valid;
		@Prop({ default: null, required: false }) private emit_on_init;
		@Prop({ default: null, required: true }) private travel_id;

		private items: any[] = [];
		private value: any[] = [];
		private filters: any;
		private isLoaded: boolean;

		@Watch("model")
		model_change(value) {
			this.value = value;
			this.setPrice();
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.filters.travel_id = value;
			this.getSelectorList();
		}

		constructor() {
			super();
			this.isLoaded = false;
			this.filters = { travel_id: null };
		}

		mounted() {
			if (this.model) {
				this.value = this.model;
			}
			if (this.travel_id) {
				this.filters.travel_id = this.travel_id;
			}
			if (this.filters.travel_id) {
				this.getSelectorList();
			}
		}

		getSelectorList() {
			ExtraServiceService.getSelectorList(null, this.filters)
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data.elements;
						this.isLoaded = true;
						if (this.emit_on_init && this.model) {
							this.setPrice();
						}
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}

		setPrice(){
			let total = 0;
			// Si se han cargado las opciones, se comprueba que las opciones que tuvieran marcadas existan dentro del filtro de personas
			if (this.isLoaded) {
				for (const index in this.value) {
					let exist = false;
					for (const item of this.items) {
						if (this.value[index].extra_service_id == item.id) {
							for (const price of item.prices) {
								if (this.value[index].prices.id == price.id) {
									exist = true;
									break;
								}
							}
						}
					}
					if (!exist) {
						this.value.splice(parseInt(index), 1);
					}
				}
			}
			for (const extraService of this.value) {
				total += extraService.prices.value;
			}
			this.$emit('input', this.value);
			this.$emit('setPrice', total);
		}
	}
