import moment from "moment";


export default class FormatHelper {

	public static formatDate(date_value) {
		const date = new Date(date_value.replace(/-/g, "/"));
		return  moment(date).format('DD/MM/YYYY');
	}

	public static formatPrice(value) {
		if (isNaN(value)) {
			return value;
		}
		const val = (value / 1).toFixed(2).replace('.', ',');
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}
}
