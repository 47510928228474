import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import axios from 'axios'
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/en.json';

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  return {}
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

const loadedLanguages: string[] = [];

export function setI18nLanguage (lang) {
	i18n.locale = lang
	axios.defaults.headers.common['Accept-Language'] = lang
	document.querySelector('html')!.setAttribute('lang', lang)
	return lang
}

export function loadLanguageAsync(lang) {
	if (loadedLanguages.indexOf(lang) > -1) {
		return Promise.resolve(setI18nLanguage(lang))
	}
	return axios.get('/translation/' + lang).then(
		res => {
			if (res.status === 200) {
				const data = res.data;
				data.validations = lang == 'es' ? es.messages : en.messages;
				i18n.setLocaleMessage(lang, data)
				loadedLanguages.push(lang)
				return setI18nLanguage(lang)
			}
		}
	)
}

export function addLoadedLang(lang) {
	loadedLanguages.push(lang)
}

export default i18n
