export default class Budget {
	id: any;
	students: any;
	teachers: any;
	start_date: any;
	end_date: any;
	who: any;
	responsible_name_surname: any;
	center: any;
	responsible_email: any;
	responsible_phone: any;
	others: any;
	terms: any;
	privacity: any;
	budget_id: any;
	customer_state_id: any;
	customer_state_name: any;
	customer_id: any;
	ages_id: any;
	travel_id: any;
	free_teacher_by_students: any;
	supplement: any;
	customer_budget_id: any;
	accommodation: any;
	transport: any;
	extra_service: any;
	activities: any;
	type: any;
	travel_duration_max: any;
	travel_duration_min: any;

	constructor() {
		this.id = null;
		this.students = null;
		this.teachers = null;
		this.start_date = null;
		this.end_date = null;
		this.who = null;
		this.responsible_name_surname = null;
		this.center = null;
		this.responsible_email = null;
		this.responsible_phone = null;
		this.others = null;
		this.terms = null;
		this.privacity = null;
		this.budget_id = null;
		this.customer_state_id = null;
		this.customer_state_name = null;
		this.customer_id = null;
		this.ages_id = null;
		this.travel_id = null;
		this.free_teacher_by_students = null;
		this.supplement = null;
		this.customer_budget_id = null;
		this.accommodation = null;
		this.transport = {};
		this.extra_service = null;
		this.activities = null;
		this.type = null;
		this.travel_duration_max = null;
		this.travel_duration_min = null;
	}

	public static copy(model: any) {
		const aux = new Budget();
		aux.id = model.id;
		aux.students = model.students;
		aux.teachers = model.teachers;
		aux.start_date = model.start_date;
		aux.end_date = model.end_date;
		aux.who = model.who;
		aux.responsible_name_surname = model.responsible_name_surname;
		aux.center = model.center;
		aux.responsible_email = model.responsible_email;
		aux.responsible_phone = model.responsible_phone;
		aux.others = model.others;
		aux.terms = model.terms;
		aux.privacity = model.privacity;
		aux.budget_id = model.budget_id;
		aux.customer_state_id = model.customer_state_id;
		aux.customer_state_name = model.customer_state_name;
		aux.customer_id = model.customer_id;
		aux.ages_id = model.ages_id;
		aux.travel_id = model.travel_id;
		aux.free_teacher_by_students = model.free_teacher_by_students;
		aux.supplement = model.supplement;
		aux.customer_budget_id = model.customer_budget_id;
		aux.accommodation = model.accommodation;
		aux.transport = model.transport ? model.transport : {};
		aux.extra_service = model.extra_service;
		aux.activities = model.activities;
		aux.type = model.type;
		aux.travel_duration_max = model.travel_duration_max;
		aux.travel_duration_min = model.travel_duration_min;
		return aux;
	}
}
