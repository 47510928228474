
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component({})
	export default class ImageUpload extends Vue {
		@Prop({ default: null, required: false }) private model;
		@Prop({ default: null, required: false }) private errors;
		@Prop({ default: 'img_url', required: false }) private label;

		private value = null;

		clearImage() {
			this.value = null;
			this.$emit("input", this.value);
		}

		get isUploaded() {
			return typeof this.model === "string";
		}

		get getImgUrl() {
			return process.env.VUE_APP_API_IMG_URL + this.model;
		}
	}
