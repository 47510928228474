import ActivityPrice from "@/models/activity_price";

export default class Activity {

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;

	id: any;
	name: string;
	description: string;
	address: string;
	lat: any;
	lon: any;
	img_url: any;
	time_type: any;
	travel_id: any;
	status_id: any;
	availability_start_date: any;
	availability_end_date: any;
	prices: ActivityPrice[];
	filters: any[];
	provider_ids: any[];
	order_column: number;

	constructor() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.address = '';
		this.lat = null;
		this.lon = null;
		this.img_url = null;
		this.time_type = null;
		this.travel_id = null;
		this.availability_start_date = null;
		this.availability_end_date = null;
		this.status_id = Activity.UNPUBLISHED;
		this.prices = [];
		this.filters = [];
		this.provider_ids = [];
		this.order_column = 1;
	}

	public static copy(model: any) {
		const aux = new Activity();
		aux.id = model.id;
		aux.name = model.name;
		aux.description = model.description;
		aux.address = model.address;
		aux.lat = model.lat;
		aux.lon = model.lon;
		aux.img_url = model.img_url;
		aux.time_type = model.time_type;
		aux.travel_id = model.travel_id;
		aux.status_id = model.status_id;
		aux.prices = model.prices;
		aux.filters = model.filters;
		aux.provider_ids = model.provider_ids;
		aux.order_column = model.order;
		aux.availability_start_date = model.availability_start_date;
		aux.availability_end_date = model.availability_end_date;
		return aux;
	}
}
