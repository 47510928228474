import Vue from 'vue'
import Vuex from 'vuex'
import Store from '@/store/index'

Vue.use(Vuex)

export const authGuard = (to, from, next) => {
    const currentUser = Store.getters.isAuthUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if(requiresAuth && !currentUser){
        next('/');
    } else{
        next();
    }
}
