
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import TransportSelect from "@/components/inputs/TransportSelect.vue";

	@Component({
		components: {
			TransportSelect,
			ValidationProvider,
			ValidationObserver,
		},
	})
	export default class TabTransportPackage extends Vue {
		@Prop({ default: null, required: true }) private packageTransport;
		@Prop({ default: null, required: true }) private travel_id;
		@Prop({ default: null, required: true }) private days;
		@Prop({ default: null, required: true }) private people;
		@Prop({ default: null, required: false }) private errors;

		private packageTransportData: any;
		private travelId: any;
		private componentPeople: any;

		$refs!: {
			transportSelect: InstanceType<typeof TransportSelect>;
		};

		@Watch("packageTransport")
		package_transport_change(value) {
			this.copyPackageTransport(value);
		}

		@Watch("travel_id")
		travel_id_change(value) {
			this.travelId = value;
		}

		@Watch("people")
		people_change(value) {
			this.componentPeople = value;
		}

		constructor() {
			super();
			this.packageTransportData = {
				id: null,
				budget_transport_id: null,
				prices: [],
				price_ids: [],
				extras: [],
				extra_ids: [],
			}
			this.travelId = null;
		}

		mounted() {
			if(this.packageTransport){
				this.copyPackageTransport(this.packageTransport);
			}
			if(this.travel_id){
				this.travelId = this.travel_id;
			}
			if (this.people != null) {
				this.componentPeople = this.people;
			}
		}

		copyPackageTransport(data){
			this.packageTransportData = {};
			this.packageTransportData.id = data.id;
			this.packageTransportData.budget_transport_id = data.budget_transport_id;
			this.packageTransportData.prices = data.prices;
			this.packageTransportData.price_ids = data.price_ids;
			this.packageTransportData.extras = data.extras;
			this.packageTransportData.extra_ids = data.extra_ids;
		}

		setPrice(value){
			let total = 0;
			for (const price of value.prices) {
				total += price.price;
			}
			for (const extra of value.extras) {
				total += extra.price;
			}
			this.$emit('setPrice', total);
		}

		async validate() {
			this.$emit("input", this.packageTransportData);
			return await this.$refs.transportSelect.validate();
		}
	}
