import axios from 'axios';
import ApiHelper from '@/helpers/api-helper';

export default class BudgetService {

	public static BUDGET_TYPE = 1;
	public static BUDGET_PACKAGE_TYPE = 2;

	private static fields = [
		'students',
		'teachers',
		'start_date',
		'end_date',
		'who',
		'responsible_name_surname',
		'center',
		'responsible_email',
		'responsible_phone',
		'others',
		'budget_id',
		'customer_state_id',
		'customer_id',
		'ages_id',
		'travel_id',
		'customer_budget_id',
		'accommodation',
		'transport',
		'extra_service',
		'activities',
		'type',
		'terms',
		'privacity',
	];

    private static pdf_fields = [
        'admin_total_amount',
        'admin_notes',
        'admin_accommodation_notes',
        'admin_transport_notes',
        'admin_extra_service_notes',
        'admin_activity_notes',
        'accommodation',
        'transport',
        'extra_services',
        'activities',
    ];

    public static get(id) {
        return axios.get('/budget/' + id);
    }

    public static getPdfDetails(id) {
        return axios.get('/budget/pdf/details/' + id);
    }

    public static list(pagination, filters = {}) {
        const params = ApiHelper.setQueryParams(pagination, filters);
        return axios.get('/budget', { params: params });
    }

    public static update(budget) {
		budget.activities = this.prepareActivities(budget);
		const data = ApiHelper.buildParamsWithFile(this.fields, budget);
        return axios.post('/budget/' + budget.id, data);
    }

    public static updatePDF(budget) {
        const data = ApiHelper.buildParams(this.pdf_fields, budget);
        return axios.put('/budget/pdf/' + budget.id, data);
    }

	public static resendEmail(id) {
		return axios.get(`/budget/resend-email/${id}`);
	}
	public static externalSync(id) {
		return axios.get(`/budget/external-sync/${id}`);
	}

	private static prepareActivities(budget) {
		const res: any[] = [];
		for (const day of budget.activities) {
			if (day.day_part_1 && day.day_part_1.activity_id) {
				res.push(day.day_part_1);
			}
			if (day.day_part_2 && day.day_part_2.activity_id) {
				res.push(day.day_part_2);
			}
			if (day.day_part_3 && day.day_part_3.activity_id) {
				res.push(day.day_part_3);
			}
		}
		return res;
	}

}
