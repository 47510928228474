export default class ExtraServicePrice {
	id: any;
	price: number;
	description: string;
	people_max: number;
	people_min: number;
	extra_service_type_id: any;
	extra_service_type_type_id: any;

	constructor() {
		this.id = null;
		this.price = 0;
		this.description = '';
		this.people_max = 0;
		this.people_min = 0;
		this.extra_service_type_id = null;
		this.extra_service_type_type_id = null;
	}
}
