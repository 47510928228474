export default class Provider {
	id: any;
	business_name: string;
	cif: string;
	addresss: string;
	email: string;
	phone: string;
	contact_person: string;
	contact_person_email: string;
	contact_person_phone: string;
	web: string;
	note: string;

	constructor() {
		this.id = null;
		this.business_name = '';
		this.cif = '';
		this.addresss = '';
		this.email = '';
		this.phone = '';
		this.contact_person = '';
		this.contact_person_email = '';
		this.contact_person_phone = '';
		this.web = '';
		this.note = '';
	}

	public static copy(model: any) {
		const aux = new Provider();
		aux.id = model.id;
		aux.business_name = model.business_name;
		aux.cif = model.cif;
		aux.addresss = model.addresss;
		aux.email = model.email;
		aux.phone = model.phone;
		aux.contact_person = model.contact_person;
		aux.contact_person_email = model.contact_person_email;
		aux.contact_person_phone = model.contact_person_phone;
		aux.web = model.web;
		aux.note = model.note;
		return aux;
	}
}
