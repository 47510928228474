<template>
	<ActivityTable/>
</template>

<script>
	import ActivityTable from '@/components/tables/ActivityTable.vue'

	export default {
		name: 'Activities',
		components: {
			ActivityTable,
		},
	}
</script>
