export default class AccommodationExtraPrice {
  id: any;
  name: string;
  description: string;
  price: number;
  people_min: number;
  people_max: number;
  required: any;
  accommodation_id: any;
  group: any;


  constructor() {
    this.id = null;
    this.name = '';
    this.description = '';
    this.price = 0;
    this.people_min = 0;
    this.people_max = 0;
    this.required = 0;
    this.accommodation_id = null;
    this.group = 0;
  }

  public static copy(model: any) {
    const aux = new AccommodationExtraPrice();
    aux.id = model.id;
    aux.name = model.name;
    aux.description = model.description;
    aux.price = model.price;
    aux.people_min = model.people_min;
    aux.people_max = model.people_max;
    aux.required = model.required;
    aux.accommodation_id = model.accommodation_id;
    aux.group = model.group;
    return aux;
  }
}
