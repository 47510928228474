
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import ApiHelper from '@/helpers/api-helper';
	import CityService from '@/services/CityService';

	@Component
	export default class CityFilter extends Vue {
		@Prop({default: null, required: false}) private state_id;
		@Prop({default: null, required: false}) private country_id;
		@Prop({default: false, required: false}) private disabled;

		@Watch('state_id')
		state_id_change(value: string) {
			this.state_id = value;
			this.filterBy = 'state_id';
			this.getList();
		}

		@Watch('country_id')
		country_id_change(value: string) {
			this.country_id = value;
			this.filterBy = 'country_id';
			this.getList();
		}

		@Watch('disabled')
		disabled_change(value: string) {
			this.disabled = value;
			this.$forceUpdate();
		}

		private items: {}[] = [];
		private filter = null;

		private filterBy = '';

		mounted() {
			// this.getList();
		}

		getList() {
			let filter_id = null;
			if (this.state_id) {
				filter_id = this.state_id;
			} else if (this.country_id) {
				filter_id = this.country_id;
			}
			CityService.getSelectorWithTravelsList(filter_id, this.filterBy).then(
				res => {
					if (res.status === 200) {
						this.prepareOptions(res.data);
					}
				}).catch((error) => {
				ApiHelper.handleErrors(error, {}, this.$bus);
			});
		}

		prepareOptions(data) {
			this.items = [];
			for (const city of data) {
				this.items.push({text: city.name, value: city.id});
			}
		}
	}
