<template>
	<CustomerTable/>
</template>

<script>
	import CustomerTable from '@/components/tables/ExtraServiceTable.vue'

	export default {
		name: 'Services',
		components: {
			CustomerTable,
		},
	}
</script>
