
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Country from '@/components/inputs/CountrySelect.vue';
import Province from '@/components/inputs/ProvinceSelect.vue';
import City from '@/components/inputs/CitySelect.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import TagSelect from '@/components/inputs/TagSelect.vue';
import TravelService from '@/services/TravelService';
import Travel from '@/models/travel';
import ApiHelper from '@/helpers/api-helper';
import TextEditor from '@/components/inputs/TextEditor.vue';
import ImageUpload from '@/components/inputs/ImageUpload.vue';

@Component({
	components: {
		ImageUpload,
		TextEditor,
		City,
		Country,
		Province,
		DatePicker,
		TagSelect,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class TravelForm extends Vue {
	@Prop({ default: null, required: true }) private edit_id;

	$refs!: {
		observer: InstanceType<typeof ValidationObserver>;
	};

	private loading = false;
	private formTitle = '';
	private travel_id: any = null;
	private travel: Travel = new Travel();

	mounted() {
		this.travel_id = this.edit_id;
		if (this.travel_id) {
			this.loading = true;
			this.formTitle = 'Edit Travel';
			TravelService.get(this.travel_id)
				.then(res => {
					if (res.status === 200) {
						this.travel = res.data;
						this.loading = false;
					}
				})
				.catch(error => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		} else {
			this.formTitle = 'New Travel';
		}
	}

	create() {
		const component = this;
		TravelService.create(this.travel)
			.then(res => {
				if (res.status === 201) {
					this.$emit('saved');
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
			});
	}

	update() {
		const component = this;
		TravelService.update(this.travel)
			.then(res => {
				if (res.status === 200) {
					this.$emit('saved');
				}
			})
			.catch(error => {
				ApiHelper.handleErrors(error, component.$refs['obs'], this.$bus);
			});
	}
}
