
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TransportSelect from "@/components/inputs/TransportSelect.vue";
import Budget from '@/models/budget';
import ProvinceSelect from '@/components/inputs/ProvinceSelect.vue';

@Component({
	components: {
		ProvinceSelect,
		TransportSelect
	},
})
export default class TabBudgetTransport extends Vue {
	@Prop({ default: null, required: true }) private budget;
	@Prop({ default: null, required: true }) private people;
	@Prop({ default: null, required: true }) private days;
	@Prop({ default: null, required: false }) private errors;

	private transport_budget: any;

	constructor() {
		super();
		this.transport_budget = new Budget();
	}

	mounted() {
		if (this.budget) {
			this.transport_budget = Budget.copy(this.budget);
		}
	}

	setPrice(value){
		let total = 0;
		for (const price of value.prices) {
			total += price.price;
		}
		for (const extra of value.extras) {
			total += extra.price;
		}
		this.$emit('setPrice', total);
	}

	async validate() {
		this.$emit("input", this.transport_budget);
		return true;
		// return await this.$refs.transportSelect.validate();  creo que no haría falta, el transporte no es obligatorio
	}
}
