
	import { Component, Prop, Vue } from "vue-property-decorator";
	import CountryService from "@/services/CountryService";
	import ApiHelper from "@/helpers/api-helper";

	@Component({})
	export default class Country extends Vue {
		@Prop({ default: null, required: false }) private id_country;
		@Prop({ default: null, required: false }) private errors;

		private items: any[] = [];
		private value = null;

		mounted() {
			CountryService.getSelectorList()
				.then((res) => {
					if (res.status === 200) {
						this.items = res.data;
						this.value = this.id_country;
					}
				})
				.catch((error) => {
					ApiHelper.handleErrors(error, {}, this.$bus);
				});
		}
	}
