
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';

@Component({
	components: {
		Editor,
	},
})
export default class TextEditor extends Vue {
	@Prop({ default: null, required: false }) private model;
	@Prop({ default: function () {return []}, required: false }) private errors;
	@Prop({ default: null, required: false }) private height;
	@Prop({ default: null, required: false }) private id;
	@Prop({ default: false, required: false }) private hasInfoButton;

	@Watch('model')
	modelChange(value) {
		this.value = value;
	}

	private value = '';
	private api_key = process.env.VUE_APP_TINY_MCE_API_KEY;
	private initKey = 0;

	private config = {
		language: 'es',
		menubar: false,
		paste_as_text: true,
		plugins: ['advlist lists autolink link charmap preview anchor insertdatetime media paste wordcount paste'],
		toolbar:
			'undo redo | forecolor backcolor | bold italic underline | aligncenter alignjustify alignleft alignright alignnone | bullist numlist | link | removeformat ',
		content_style: `
    .alert {
		width: 100%;
		padding: 12px;
		display: block;
		margin: 1rem 0;
		border-radius: 8px;
		font-size: 14px;
		line-height: 1.5em;
		border: 0;
    }
    .alert-info {
      color: #057c7c;
      background-color: #def8f3;
    }
    .alert-warning {
      color: #dd2526;
      background-color: #fef2f2;
    }
  `,
		setup: function (editor) {
			// Botón para alert-info
			editor.ui.registry.addButton('infoButton', {
				text: 'Info', // Texto del botón
				onAction: function () {
					editor.insertContent('<div class="alert alert-info" contenteditable="true">Escribe aquí tu información...</div><br>');
				}
			});

			// Botón para alert-danger
			editor.ui.registry.addButton('alertButton', {
				text: 'Alerta', // Texto del botón
				onAction: function () {
					editor.insertContent('<div class="alert alert-warning" contenteditable="true">Escribe aquí tu alerta...</div><br>');
				}
			});
		}


	};

	mounted() {
		if (this.model) {
			this.value = this.model;
		}
		if (this.hasInfoButton)
		{
			this.config.toolbar += ' | infoButton  alertButton';
			this.initKey ++;
		}
		// if (this.height) {
		// 	this.config.height = this.height;
		// }
	}
}
