
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DatePicker extends Vue {
	@Prop({ default: null, required: false }) private model;
	@Prop({ default: null, required: true }) private label;
	@Prop({ default: null, required: false }) private errors;
	@Prop({ default: null, required: false }) private valid;
	@Prop({ default: false, required: false }) private initialize;
	@Prop({ default: false, required: false }) private initialWithMin;
	@Prop({ default: false, required: false }) private disabled;
	@Prop({ default: false, required: false }) private clearable;
	@Prop({ default: '', required: false }) private min;
	@Prop({ default: '', required: false }) private max;

	@Watch('model')
	model_change(value) {
		this.date = value;
	}

	private date: string;
	private formatted_date: string;
	private locale: string;
	private menuDate: boolean;

	constructor() {
		super();
		this.date = '';
		this.formatted_date = '';
		this.locale = '';
		this.menuDate = false;
	}

	mounted() {
		this.locale = this.$i18n.locale;
		if (this.model) {
			this.date = this.model;
		} else if (this.initialize) {
			this.date = new Date().toISOString().substr(0, 10);
		}
	}

	setDate() {
		if (this.min && this.initialWithMin) {
			this.date = this.min;
		}

	}

	get formattedDate() {
		if (this.date) {
			const [year, month, day] = this.date.split('-');
			if (this.locale === 'es') {
				this.formatted_date = `${day}/${month}/${year}`;
			} else {
				this.formatted_date = `${year}/${month}/${day}`;
			}
		}
		return this.formatted_date;
	}

	set formattedDate(value: any) {
		this.formatted_date = value;
	}
}
