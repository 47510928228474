import axios from 'axios';
import ApiHelper from "@/helpers/api-helper";
import Package from "@/models/package";

export default class PackageService {
	private static fields = [
		'name',
		'description',
		'img_url',
		'travel_id',
		'budget_id',
		'days',
		'status_id',
		'offer',
		'offer_start_date',
		'offer_end_date',
		'offer_amount',
		'people',
		'travel',
		'accommodation',
		'transport',
		'extra_service',
		'activities'
	]

	public static get(id) {
		return axios.get(`/travel_pack/${id}`);
	}

	public static list(pagination, filters) {
		const params = ApiHelper.setQueryParams(pagination, filters);
		return axios.get("/travel_pack", { params: params });
	}

	public static create(travel_pack: Package){
		travel_pack.activities = this.prepareActivities(travel_pack);
		const data = ApiHelper.buildParamsWithFile(this.fields, travel_pack);
		return axios.post('/travel_pack', data,{
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		})
	}

	public static update(travel_pack: Package){
		travel_pack.activities = this.prepareActivities(travel_pack);
		const data = ApiHelper.buildParamsWithFile(this.fields, travel_pack);
		return axios.post(`/travel_pack/${travel_pack.id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	public static changeStatus(travel_pack: Package) {
		return axios.get(`/travel_pack/changeStatus/${travel_pack.id}/${travel_pack.status_id}`);
	}

	public static delete(id) {
		return axios.delete(`/travel_pack/${id}`);
	}

	private static prepareActivities(travel_pack) {
		const res: any[] = [];
		for (const day of travel_pack.activities) {
			if (day.day_part_1 && day.day_part_1.activity_id) {
				res.push(day.day_part_1);
			}
			if (day.day_part_2 && day.day_part_2.activity_id) {
				res.push(day.day_part_2);
			}
			if (day.day_part_3 && day.day_part_3.activity_id) {
				res.push(day.day_part_3);
			}
		}
		return res;
	}
}
