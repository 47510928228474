export default class Package {
	id: any;
	name: string;
	slug: string;
	description: string;
	img_url: any;
	budget_id: any;
	status_id: any;
	travel_id: any;
	days: any;
	offer: any;
	offer_start_date: any;
	offer_end_date: any;
	offer_amount: number;
	people: number;
	created_at: any;

	accommodation: {
		id: any;
		feature_id: any;
		price_id: any;
		prices: any[];
		extras: any[];
		extra_ids: any[];
	}
	transport: {
		id: any;
		budget_transport_id: any;
		prices: any;
		extras: any;
		extra_ids: any;
	}
	extra_service: any;
	activities: any[]

	constructor() {
		this.id = null;
		this.name = '';
		this.slug = '';
		this.description = '';
		this.img_url = null;
		this.budget_id = null;
		this.status_id = 0;
		this.travel_id = null;
		this.days = '';
		this.created_at = null;
		this.offer = 0;
		this.offer_start_date = null;
		this.offer_end_date = null;
		this.offer_amount = 0;
		this.people = 0;
		this.accommodation = {
			id: null,
			feature_id: null,
			price_id: null,
			prices: [],
			extras: [],
			extra_ids: []
		}
		this.transport = {
			id: null,
			budget_transport_id: null,
			prices: [],
			extras: [],
			extra_ids: []
		}
		this.extra_service = []
		this.activities = []
	}

	public static copy(model: any) {
		const aux = new Package();
		aux.id = model.id;
		aux.name = model.name;
		aux.slug = model.slug;
		aux.description = model.description;
		aux.img_url = model.img_url;
		aux.budget_id = model.budget_id;
		aux.status_id = model.status_id;
		aux.travel_id = model.travel_id;
		aux.days = model.days;
		aux.accommodation = model.accommodation;
		aux.transport = model.transport;
		aux.extra_service = model.extra_service;
		aux.activities = model.activities;
		aux.created_at = model.created_at;
		aux.offer = model.offer;
		aux.offer_start_date = model.offer_start_date;
		aux.offer_end_date = model.offer_end_date;
		aux.offer_amount = model.offer_amount;
		aux.people = model.people;
		return aux;
	}

	public static PUBLISHED = 1;
	public static UNPUBLISHED = 0;
}
